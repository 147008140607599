import { FC, useContext, useEffect, useState } from "react";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import { Iban, PaginationInput, User } from "src/api/types";
import {
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  NetworkDisconnectMsg,
} from "src/lib/constants";
import { capitalizeEachFirstLetter, getErrorMessage } from "src/lib/utils";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { IbanDataType } from "src/lib/constants/table-type";

type TTabs = "card" | "iban";

const tabs: { code: TTabs; name: string }[] = [
  {
    code: "card",
    name: "Card",
  },
  {
    code: "iban",
    name: "IBAN",
  },
];

type UserPaymentMethodsPageProps = {
  user: User;
};

const UserPaymentMethodIbans: FC<UserPaymentMethodsPageProps> = ({ user }) => {
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const [ibans, setIbans] = useState<Iban[]>([]);
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<IbanDataType[]>([]);
  const [currentTab, setCurrentTab] = useState<TTabs>("card");

  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });

  const { mutate: getIbans, isLoading: isIndividualCardsLoading } = useMutation(
    user.is_business
      ? client.users.getBusinessIbanAccounts
      : client.users.getIndividualIbanAccounts,
    {
      onSuccess: (data) => {
        setTotal(data.total);
        setIbans(data.data);
        setDataSource(
          data.data.map((item) => ({
            key: item.id,
            id: item.id,
            beneficiary_name: item.beneficiary_name,
            asset_code: item.asset_code,
            bank_name: item.bank_name,
            swift: item.swift,
            iban: item.iban,
            address: item.address,
            payment_purpose: item.payment_purpose,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onPageChange = (page: number, pageSize: number) => {
    setSearchParams({ ...searchParams, current_page: page });
  };

  useEffect(() => {
    if (!isMounted) {
      return;
    }

    getIbans({ id: user.id, params: searchParams });
  }, [isMounted, user]);

  useEffect(() => {
    // isMounted && getPaymentMethods({ id: user.id, params: searchParams });
  }, [isMounted, searchParams.current_page]);

  const columns: ColumnsType<IbanDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Beneficiary name
        </p>
      ),
      dataIndex: "beneficiary_name",
      key: "beneficiary_name",
      className: "w-[200px]",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Currency
        </p>
      ),
      dataIndex: "asset_code",
      key: "asset_code",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value?.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Bank name
        </p>
      ),
      dataIndex: "bank_name",
      key: "bank_name",
      render: (value: string) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {value}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          SWIFT
        </p>
      ),
      dataIndex: "swift",
      key: "swift",
      className: "w-[200px]",
      render: (value: string) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {value}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          IBAN number
        </p>
      ),
      dataIndex: "iban",
      key: "iban",
      render: (value: string) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {value}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Address
        </p>
      ),
      dataIndex: "address",
      key: "address",
      render: (value: string) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
          {value}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] text-right font-medium">
          Purpose of payment
        </p>
      ),
      dataIndex: "payment_purpose",
      key: "payment_purpose",
      className: "w-[200px]",
      render: (value: string) => (
        <p className="font-manrope text-primary text-[13px] sm:text-[14px] text-right font-medium">
          {value}
        </p>
      ),
    },
  ];

  return (
    <Table
      loading={isIndividualCardsLoading}
      showSorterTooltip={false}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        total,
        current: searchParams.current_page,
        onChange: onPageChange,
        position: ["bottomCenter"],
        defaultPageSize: LIST_PAGE_SIZE,
        showSizeChanger: false,
      }}
    />
  );
};

export default UserPaymentMethodIbans;
