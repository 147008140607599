import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import { DateRangePicker } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  convertPaymentSystem,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Dropdown, MenuProps, Space, Spin, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  pendingWithdrawalFilterOptions,
} from "src/lib/constants";
import { PaginationInput, User } from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import {
  TransactionDataType,
  CurrencyDataType,
} from "src/lib/constants/table-type";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import { ThreeDotsIcon } from "src/components/icons/three-dots-icon";
import routes from "src/config/routes";
import { useNavigate } from "react-router-dom";
import InvoicePage from "src/components/invoice";
import UsersContainerPage from "src/components/users";
import { BackCircleIcon } from "src/components/icons/back-circle-icon";
import Button from "src/components/ui/button";
import SearchFilter from "src/components/search-filters/pending-withdrawal-filter";

interface IPendingWithdrawalsPage {
  isBuisiness?: boolean;
}

const items: MenuProps["items"] = [
  {
    key: "1",
    label: (
      <a className="text-primary text-[13px] font-manrope font-light">
        Confirm
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a className="!text-error text-[13px] font-manrope font-light">Reject</a>
    ),
  },
];

const PendingWithdrawalsPage: FC<IPendingWithdrawalsPage> = ({
  isBuisiness,
}) => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });
  const [searchText, setSearchText] = useState("");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal } = useModalAction();
  const [selectedUserID, setSelectedUserID] = useState(-1);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState<TransactionDataType[]>([]);
  const [invoiceData, setInvoiceData] = useState<TransactionDataType | null>(
    null
  );

  const [currencies, setCurrencies] = useState<CurrencyDataType[]>([]);

  const [filter, setFilter] = useState("user_id");

  const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);

  const now = new Date();
  const prevMonth = new Date(now.setMonth(now.getMonth() - 1));

  const [startDate, endDate] = dateRangeValue || [];

  const { mutate: exportList } = useMutation(
    isBuisiness
      ? client.pendingWithdrawals.buisiness
      : client.pendingWithdrawals.individual,
    {
      onSuccess: (data) => {
        setAlertText("Export success");
        setIsAlertOpened(true);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const exportBtn = async () => {
    try {
      setTimeout(() => {
        exportList({
          ...searchParams,
          search_value: searchText || undefined,
          search_field: searchText ? filter : undefined,
          start_date: startDate
            ? moment(startDate).format("yyyy-MM-DD")
            : undefined,
          end_date: endDate ? moment(endDate).format("yyyy-MM-DD") : undefined,
          export: 1,
        });
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  const { mutate: getCurrencies } = useMutation(client.currencies.all, {
    onSuccess: (data) => {
      console.log(data);
      setCurrencies(
        data.map((item) => ({
          key: item.id,
          ...item,
        }))
      );
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  useEffect(() => {
    isMounted && getCurrencies();
  }, [isMounted]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted) {
        console.log("called");
        getList({
          ...searchParams,
          search_value: searchText.length > 0 ? searchText : undefined,
          search_field: searchText.length > 0 ? filter : undefined,
          start_date: startDate
            ? moment(startDate).format("yyyy-MM-DD")
            : undefined,
          end_date: endDate ? moment(endDate).format("yyyy-MM-DD") : undefined,
        });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [
    isMounted,
    searchParams.current_page,
    searchText,
    filter,
    startDate,
    endDate,
    isBuisiness,
  ]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      current_page: 1,
    });
  }, [searchText]);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({
      ...searchParams,
      current_page: page,
    });
  };

  const onSelectedInvoiceData = (data: TransactionDataType) => {
    setInvoiceData(data);
  };

  const onBackFromInvoice = () => {
    setInvoiceData(null);
  };

  const onViewInvoice = (key: number) => {
    const filterInvoices = dataSource.filter((item) => item.key === key);
    if (filterInvoices.length === 0) {
      return;
    }

    const selInvoice = filterInvoices[0];
    setInvoiceData(selInvoice);
    /*
    setInvoiceFromPage(routes.pendingWithdrawals);
    selInvoice.invoice_data &&
      navigate(routes.invoice, { state: { info: selInvoice } });
      */
  };

  const onSelectUser = (userID: number) => {
    getUsers({
      per_page: LIST_PAGE_SIZE,
      current_page: 1,
      search_value: Number(userID),
      search_field: "user_id",
    });
  };

  const { mutate: getUsers, isLoading: isLoadingUsers } = useMutation(
    client.users.getUsers,
    {
      onSuccess: (data) => {
        if (data.users.length > 0) {
          setSelectedUser(data.users[0]);
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getList, isLoading } = useMutation(
    isBuisiness
      ? client.pendingWithdrawals.buisiness
      : client.pendingWithdrawals.individual,
    {
      onSuccess: (data) => {
        console.log(data);
        setTotal(data.total);

        setDataSource(
          data.data.map((item) => ({
            key: item.id,
            user_id: item.user_id,
            asset_id: item.asset_id,
            amount: item.amount,
            total_amount: item.total_amount,
            amount_fee: item.amount_fee,
            payment_system: item.payment_system,
            status: item.status,
            type: item.type,
            description: item.description,
            comment: item.comment,
            to: item.to,
            withdraw_payment_system: item.withdraw_payment_system,
            refund_id: item.refund_id,
            created_at: item.created_at,
            updated_at: item.updated_at,
            indicated_amount: item.indicated_amount,
            asset_code: item.asset.code,
            invoice: item.requisites ? true : false,
            invoice_data: item.requisites,
            full_name: item.full_name,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onCallback = () => {
    getList(searchParams);
  };

  const onConfirm = (key: number) => {
    const selData = dataSource.filter((item) => item.key === key)[0];

    openModal("PENDING_CONFIRM_VIEW", {
      type: "withdraw",
      subType: "confirm",
      info: selData,
      callback: onCallback,
      isBuisiness,
    });
  };
  const onReject = (key: number) => {
    const selData = dataSource.filter((item) => item.key === key)[0];

    openModal("PENDING_CONFIRM_VIEW", {
      type: "withdraw",
      subType: "reject",
      info: selData,
      callback: onCallback,
      isBuisiness,
    });
  };

  const columns: ColumnsType<TransactionDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          ID
        </p>
      ),
      dataIndex: "key",
      className: "w-auto",
      key: "key",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          User ID
        </p>
      ),
      dataIndex: "user_id",
      className: "w-auto",
      key: "user_id",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <a
            className="font-manrope text-secondary text-[14px] font-medium underline hover:text-primary hover:underline"
            onClick={() => onSelectUser(value)}
          >
            {value}
          </a>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Full name
        </p>
      ),
      dataIndex: "full_name",
      className: "w-auto",
      key: "full_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Date
        </p>
      ),
      dataIndex: "created_at",
      className: "w-auto",
      key: "created_at",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {moment.unix(value).format("YYYY-MM-DD HH:mm:ss")}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Currency
        </p>
      ),
      dataIndex: "asset_code",
      className: "w-auto",
      key: "asset_code",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[13px] sm:text-[14px] font-medium">
            {value.toUpperCase()}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Amount
        </p>
      ),
      dataIndex: "total_amount",
      key: "total_amount",
      className: "w-auto",
      render: (value: number) => (
        <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
          {makeNumberWithFloatingDigits(value, 2)}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Payment Method
        </p>
      ),
      dataIndex: "payment_system",
      className: "w-auto",
      key: "payment_system",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[13px] sm:text-[14px] font-medium">
            {convertPaymentSystem(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Invoice
        </p>
      ),
      dataIndex: "invoice",
      className: "w-auto",
      render: (
        _,
        record: { key: React.Key; invoice: boolean; invoice_data?: any }
      ) => (
        <div
          className={`${
            !record.invoice ? "invisible" : ""
          } flex flex-row gap-4`}
        >
          <a
            className="text-secondary underline hover:underline hover:text-primary text-[12px] font-manrope font-medium"
            onClick={() => onViewInvoice(record.key as number)}
          >
            Invoice
          </a>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action
        </p>
      ),
      dataIndex: "action",
      className: "w-[100px]",
      render: (_, record: { key: React.Key }) => {
        return (
          <div className="flex flex-row gap-4">
            <a
              className="text-primary text-[14px] font-manrope font-medium"
              onClick={() => onConfirm(record.key as number)}
            >
              Confirm
            </a>
            <a
              className="text-error text-[14px] font-manrope font-medium"
              onClick={() => onReject(record.key as number)}
            >
              Reject
            </a>
          </div>
        );
      },
    },
  ];

  const mobileColumns: ColumnsType<TransactionDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData = dataSource.filter((item) => item.key === record.key)[0];

        const onClick: MenuProps["onClick"] = ({ key }) => {
          if (key === "1") {
            onConfirm(record.key as number);
          } else {
            onReject(record.key as number);
          }
        };

        return (
          <div className="w-full flex flex-row gap-4 items-start justify-between">
            <div className="w-full flex flex-col items-start gap-2">
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  ID
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {selData ? String(selData.key) : "-"}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Full name
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {selData.full_name}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Currency
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium text-">
                  {selData.asset_code.toUpperCase()}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Amount
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {makeNumberWithFloatingDigits(selData.total_amount, 2)}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Payment Method
                </p>
                <p className="text-primary text-[14px] font-manrope font-medium">
                  {convertPaymentSystem(selData.payment_system)}
                </p>
              </div>
            </div>
            <div className="w-full flex flex-col items-start gap-2">
              <Space direction="vertical" className="h-[22px]">
                <Dropdown menu={{ items, onClick }}>
                  <a>
                    <ThreeDotsIcon className="w-[20px] h-[20px] text-primary" />
                  </a>
                </Dropdown>
              </Space>
              <p className="text-primary text-[14px] font-manrope font-medium">
                {moment.unix(selData.updated_at).format("YYYY-MM-DD HH:mm:ss")}
              </p>
              <div className="w-full flex flex-row items-center justify-between gap-2">
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  User ID.
                </p>
                <a
                  className="font-manrope text-secondary text-[14px] font-medium underline hover:text-primary hover:underline  text-right"
                  onClick={() => onSelectUser(selData.user_id)}
                >
                  {selData.user_id}
                </a>
              </div>
              <div
                className={`${
                  !selData.invoice ? "invisible" : ""
                } w-full flex flex-row items-center justify-between gap-2`}
              >
                <p className="text-secondary text-[14px] font-manrope font-medium">
                  Invoice
                </p>

                <a
                  className="text-primary underline hover:underline hover:text-secondary text-[14px] font-manrope font-medium"
                  onClick={() => onViewInvoice(selData.key as number)}
                >
                  Invoice
                </a>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full flex flex-col">
        {selectedUser == null ? (
          <div
            className={`${
              invoiceData && "hidden"
            } w-full h-full flex-col p-2 sm:p-4`}
          >
            <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
              Pending Withdrawals
            </p>
            <div className="mt-4 flex items-center justify-between w-full mb-4">
              <SearchFilter
                options={pendingWithdrawalFilterOptions}
                value={filter}
                onChange={setFilter}
                searchText={searchText}
                setSearchText={setSearchText}
                currencies={currencies}
              />
              <div className="flex gap-2">
                <DateRangePicker
                  format="dd/MM/yyyy"
                  size="lg"
                  block
                  placement="bottomEnd"
                  style={{ width: "308px" }}
                  value={dateRangeValue}
                  onChange={setDateRangeValue}
                  defaultCalendarValue={[prevMonth, now]}
                  ranges={[]}
                />
                <button
                  type="button"
                  onClick={() => exportBtn()}
                  className="button button--type3 button--gap30"
                >
                  Export Excel
                  <span className="btn-icon btn-icon--fill">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.79961 1.8002L12.6996 5.4002M8.79961 1.8002L5.19961 5.4002M8.79961 1.8002L8.79961 13.2002M15.9996 8.4002V16.2002L1.59961 16.2002L1.59961 8.4002"
                        stroke="#232800"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
              <Table
                loading={isLoading}
                showSorterTooltip={false}
                columns={isMobile ? mobileColumns : columns}
                dataSource={dataSource}
                pagination={{
                  total,
                  current: searchParams.current_page,
                  onChange: onPageChange,
                  position: ["bottomCenter"],
                  defaultPageSize: LIST_PAGE_SIZE,
                  showSizeChanger: false,
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className={`${
              invoiceData && "hidden"
            } flex flex-col w-full p-2 sm:p-4`}
          >
            <div className="flex flex-row items-center justify-between sm:justify-start gap-4">
              <div className="flex flex-row items-center gap-2">
                <Button
                  variant="icon"
                  className="w-[24px] bg-transparent h-[24px]"
                  onClick={() => {
                    setSelectedUserID(-1);
                    setSelectedUser(null);
                  }}
                >
                  <BackCircleIcon className="w-[24px] h-[24px] text-primary" />
                </Button>
                <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[38px] sm:leading-[48px]">
                  {!selectedUser.user_data.first_name &&
                  !selectedUser.user_data.last_name
                    ? "-"
                    : (selectedUser.user_data.first_name ?? " ") +
                      " " +
                      (selectedUser.user_data.last_name ?? " ")}
                </p>
              </div>
              <p
                className={`font-manrope text-[12px] font-medium px-2 rounded-full ${getStatusStyle(
                  selectedUser.status.name
                )}`}
              >
                {capitalizeFirstLetter(selectedUser.status.name)}
              </p>
            </div>
            <UsersContainerPage
              user={selectedUser}
              onSelectedInvoiceData={onSelectedInvoiceData}
            />
          </div>
        )}
      </div>
      {invoiceData && (
        <InvoicePage data={invoiceData!} callback={onBackFromInvoice} />
      )}
    </div>
  );
};

export default PendingWithdrawalsPage;
