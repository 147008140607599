import type {
  AdminUser,
  LoginUserInput,
  AuthResponse,
  ChangePasswordInput,
  StatusResponse,
  DashboardResponse,
  PaginationInput,
  UsersResponse,
  ParamsSort,
  User,
  TransactionsResponse,
  TransfersResponse,
  BankCardsResponse,
  UserBalancesResponse,
  BankAccountsResponse,
  BankAccountInput,
  BankAccount,
  BankAccountUpdate,
  Google2FASecretKeyResponse,
  Notification,
  NotificationInput,
  AdminUsersResponse,
  AdminUserInput,
  AdminUserResponse,
  AdminUserUpdate,
  Permission,
  CurrencyResponse,
  CurrencyInput,
  Limitation,
  LimitationUpdate,
  DailyLimit,
  DailyLimitUpdate,
  LimitSettings,
  LimitSettingsUpdate,
  UpdateUmlStatus,
  UpdateUserVip,
  Fee,
  FeeUpdate,
  FeeAdd,
  ExportDashboard,
  IbanResponse,
  TOurPricesResponse,
  IOurPricesItem,
  IGetOurPricesInput,
  IUpdateOurPricesInput,
  KwickpayHistoryResponse,
  IuserSettingsResponse,
  UnidentifyDepositResponse,
} from "./types";
import { API_ENDPOINTS } from "./endpoints";
import { HttpClient } from "./http-client";

class Client {
  auth = {
    login: (input: LoginUserInput) =>
      HttpClient.post<AuthResponse>(API_ENDPOINTS.USERS_LOGIN, input),
    changePassword: (input: ChangePasswordInput) =>
      HttpClient.put<StatusResponse>(API_ENDPOINTS.CHANGE_PASSWORD, input),
    logout: () =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.USERS_LOGOUT, {}),
  };
  dashboard = {
    getBalances: () =>
      HttpClient.get<DashboardResponse>(API_ENDPOINTS.GET_USERS_BALANCES),
    export: (input: ExportDashboard) =>
      HttpClient.get(API_ENDPOINTS.GET_DASHBORAD_EXPORT, input),
    getStartDate: (input: ExportDashboard) =>
      HttpClient.get(API_ENDPOINTS.GET_HISTORY_DAY, input),
  };
  users = {
    getUsers: (input: PaginationInput) =>
      HttpClient.get<UsersResponse>(API_ENDPOINTS.GET_USERS, input),
    getIndividualUsers: (input: PaginationInput) =>
      HttpClient.get<UsersResponse>(API_ENDPOINTS.GET_INDIVIDUAL_USERS, input),
    getBuisinessUsers: (input: PaginationInput) =>
      HttpClient.get<UsersResponse>(API_ENDPOINTS.GET_BUISINESS_USERS, input),
    blockUser: (input: { id: number; blocked: number }) =>
      HttpClient.put<User>(
        `${API_ENDPOINTS.GET_USER}${input.id}${API_ENDPOINTS.BLOCK_USER}`,
        { blocked: input.blocked }
      ),
    updateVIPStatus: (input: { id: number; is_vip: number }) =>
      HttpClient.put<User>(
        `${API_ENDPOINTS.GET_USER}${input.id}${API_ENDPOINTS.UPDATE_VIP}`,
        { is_vip: input.is_vip }
      ),
    getIndividualTransactions: (input: {
      id: number;
      params: PaginationInput;
    }) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_INDIVIDUAL_USER_TRANSACTIONS(input.id),
        input.params
      ),
    getBuisinessTransactions: (input: {
      id: number;
      params: PaginationInput;
    }) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_BUISINESS_USER_TRANSACTIONS(input.id),
        input.params
      ),
    getTransfers: (input: { id: number; params: PaginationInput }) =>
      HttpClient.get<TransfersResponse>(
        `${API_ENDPOINTS.GET_USER}${input.id}${API_ENDPOINTS.GET_USER_TRANSFERS}`,
        input.params
      ),
    getBankCards: (input: { id: number; params: PaginationInput }) =>
      HttpClient.get<BankCardsResponse>(
        `${API_ENDPOINTS.GET_USER}${input.id}${API_ENDPOINTS.GET_USER_BANKCARDS}`,
        input.params
      ),
    getIndividualBalances: (id: number) =>
      HttpClient.get<UserBalancesResponse>(
        API_ENDPOINTS.GET_INDIVIDUAL_USER_BALANCES(id)
      ),
    getBusinessBalances: (id: number) =>
      HttpClient.get<UserBalancesResponse>(
        API_ENDPOINTS.GET_BUISINESS_USER_BALANCES(id)
      ),
    getIndividualLimitations: (id: number) =>
      HttpClient.get<Limitation[]>(
        API_ENDPOINTS.GET_USER_INDIVIDUAL_LIMITATIONS(id)
      ),
    updateIndividualLimitation: (params: {
      input: LimitationUpdate;
      userId: number;
    }) =>
      HttpClient.put<Limitation>(
        API_ENDPOINTS.GET_USER_INDIVIDUAL_LIMITATIONS(params.userId),
        params.input
      ),
    getDailyIndividualLimitations: (id: number) =>
      HttpClient.get<DailyLimit[]>(
        API_ENDPOINTS.GET_USER_DAILY_INDIVIDUAL_LIMITATIONS(id)
      ),
    updateDailyIndividualLimitation: (params: {
      input: DailyLimit;
      userId: number;
    }) =>
      HttpClient.put<Limitation>(
        API_ENDPOINTS.GET_USER_DAILY_INDIVIDUAL_LIMITATIONS(params.userId),
        params.input
      ),
    getBuisnessLimitations: (id: number) =>
      HttpClient.get<Limitation[]>(
        API_ENDPOINTS.GET_USER_BUISNESS_LIMITATIONS(id)
      ),
    updateBuisnessLimitation: (params: {
      input: LimitationUpdate;
      userId: number;
    }) =>
      HttpClient.put<Limitation>(
        API_ENDPOINTS.GET_USER_BUISNESS_LIMITATIONS(params.userId),
        params.input
      ),
    getDailyBuisnessLimitations: (id: number) =>
      HttpClient.get<DailyLimit[]>(
        API_ENDPOINTS.GET_USER_DAILY_BUISNESS_LIMITATIONS(id)
      ),
    updateDailyBuisnessLimitation: (params: {
      input: DailyLimitUpdate;
      userId: number;
    }) =>
      HttpClient.put<Limitation>(
        API_ENDPOINTS.GET_USER_DAILY_BUISNESS_LIMITATIONS(params.userId),
        params.input
      ),
    getIndividualLimitSettings: (id: number) =>
      HttpClient.get<LimitSettings>(
        API_ENDPOINTS.USER_INDIVIDUAL_LIMITATION_SETTINGS(id)
      ),
    getBuisnessLimitSettings: (id: number) =>
      HttpClient.get<LimitSettings>(
        API_ENDPOINTS.USER_BUISNESS_LIMITATION_SETTINGS(id)
      ),
    updateIndividualLimitSettings: (params: {
      input: LimitSettingsUpdate;
      userId: number;
    }) =>
      HttpClient.put<LimitSettings>(
        API_ENDPOINTS.USER_INDIVIDUAL_LIMITATION_SETTINGS(params.userId),
        params.input
      ),
    updateBuisnessLimitSettings: (params: {
      input: LimitSettingsUpdate;
      userId: number;
    }) =>
      HttpClient.put<LimitSettings>(
        API_ENDPOINTS.USER_BUISNESS_LIMITATION_SETTINGS(params.userId),
        params.input
      ),
    updateIndividualAmlStatus: (params: {
      input: UpdateUmlStatus;
      userId: number;
    }) =>
      HttpClient.put<any>(
        API_ENDPOINTS.UDPATE_USER_UML_STATUS(params.userId),
        params.input
      ),
    updateBuisinessAmlStatus: (params: {
      input: UpdateUmlStatus;
      userId: number;
    }) =>
      HttpClient.put<any>(
        API_ENDPOINTS.UDPATE_BUISINESS_UML_STATUS(params.userId),
        params.input
      ),
    updateIndividualVip: (params: { input: UpdateUserVip; userId: number }) =>
      HttpClient.put<any>(
        API_ENDPOINTS.UPDATE_INDIVIDUAL_USER_VIP(params.userId),
        params.input
      ),
    updateBuisinessVip: (params: { input: UpdateUserVip; userId: number }) =>
      HttpClient.put<any>(
        API_ENDPOINTS.UPDATE_BUISINESS_USER_VIP(params.userId),
        params.input
      ),
    getIndividualCardNumbers: (input: {
      id: number;
      params: PaginationInput;
    }) =>
      HttpClient.get<BankCardsResponse>(
        API_ENDPOINTS.GET_INDIVIDUAL_BANK_CARDS(input.id),
        input.params
      ),
    getBusinessCardNumbers: (input: { id: number; params: PaginationInput }) =>
      HttpClient.get<BankCardsResponse>(
        API_ENDPOINTS.GET_BUISINESS_BANK_CARDS(input.id),
        input.params
      ),
    getIndividualIbanAccounts: (input: {
      id: number;
      params: PaginationInput;
    }) =>
      HttpClient.get<IbanResponse>(
        API_ENDPOINTS.GET_INDIVIDUAL_IBAN_ACCOUNTS(input.id),
        input.params
      ),
    getBusinessIbanAccounts: (input: { id: number; params: PaginationInput }) =>
      HttpClient.get<IbanResponse>(
        API_ENDPOINTS.GET_BUISINESS_IBAN_ACCOUNTS(input.id),
        input.params
      ),
  };
  bankAccount = {
    all: (input: PaginationInput) =>
      HttpClient.get<BankAccountsResponse>(
        API_ENDPOINTS.GET_BANK_ACCOUNTS,
        input
      ),
    add: (input: BankAccountInput) =>
      HttpClient.post<BankAccount>(API_ENDPOINTS.GET_BANK_ACCOUNTS, input),
    update: (input: BankAccountUpdate) =>
      HttpClient.put<BankAccount>(API_ENDPOINTS.GET_BANK_ACCOUNTS, input),
    delete: (id: number) =>
      HttpClient.delete<StatusResponse>(
        `${API_ENDPOINTS.GET_BANK_ACCOUNTS}/${id}`
      ),
  };
  transactions = {
    individualDeposit: (input: PaginationInput) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_INSIVIDUAL_DEPOSIT_TRANSACTIONS,
        input
      ),
    buisinessDeposit: (input: PaginationInput) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_BUISINESS_DEPOSIT_TRANSACTIONS,
        input
      ),
    individualWithdrawal: (input: PaginationInput) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_INDIVIDUAL_WITHDRAWAL_TRANSACTIONS,
        input
      ),
    buisinessWithdrawal: (input: PaginationInput) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_BUISINESS_WITHDRAWAL_TRANSACTIONS,
        input
      ),
    transfer: (input: PaginationInput) =>
      HttpClient.get<TransfersResponse>(API_ENDPOINTS.GET_TRANSFERS, input),
    kwickpayIndividual: (input: PaginationInput) =>
      HttpClient.get<KwickpayHistoryResponse>(
        API_ENDPOINTS.GET_INDIVIDUAL_KWICKPAY_TRANSACTIONS,
        input
      ),
    kwickpayBuisiness: (input: PaginationInput) =>
      HttpClient.get<KwickpayHistoryResponse>(
        API_ENDPOINTS.GET_BUISINESS_KWICKPAY_TRANSACTIONS,
        input
      ),
  };
  google2FA = {
    generateKey: () =>
      HttpClient.get<Google2FASecretKeyResponse>(
        API_ENDPOINTS.GOOGLE_2FA_GENERATE_KEY
      ),
    enable: (input: { totp: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.GOOGLE_2FA_ENABLE, input),
    disable: (input: { totp: string }) =>
      HttpClient.post<StatusResponse>(API_ENDPOINTS.GOOGLE_2FA_DISABLE, input),
  };
  notification = {
    all: () => HttpClient.get<Notification[]>(API_ENDPOINTS.GET_NOTIFICATIONS),
    update: (input: NotificationInput) =>
      HttpClient.put<Notification>(API_ENDPOINTS.UPDATE_NOTIFICATION, input),
  };
  admins = {
    all: (input: PaginationInput) =>
      HttpClient.get<AdminUsersResponse>(API_ENDPOINTS.GET_ADMINS, input),
    add: (input: AdminUserInput) =>
      HttpClient.post<AdminUserResponse>(API_ENDPOINTS.GET_ADMINS, input),
    update: (input: { id: number; params: AdminUserUpdate }) =>
      HttpClient.put<AdminUser>(
        `${API_ENDPOINTS.GET_ADMIN}/${input.id}`,
        input.params
      ),
    delete: (id: number) =>
      HttpClient.delete<StatusResponse>(`${API_ENDPOINTS.GET_ADMIN}/${id}`),
    permissions: () =>
      HttpClient.get<Permission[]>(API_ENDPOINTS.GET_PERMISSIONS),
  };
  currencies = {
    all: () => HttpClient.get<CurrencyResponse[]>(API_ENDPOINTS.GET_CURRENCIES),
    update: (input: CurrencyInput) =>
      HttpClient.put<CurrencyResponse>(API_ENDPOINTS.GET_CURRENCIES, input),
  };
  limitationsBusiness = {
    all: () =>
      HttpClient.get<Limitation[]>(
        API_ENDPOINTS.GET_SINGLE_BUSINESS_LIMITATIONS
      ),
    update: (input: LimitationUpdate) =>
      HttpClient.put<Limitation>(
        API_ENDPOINTS.GET_SINGLE_BUSINESS_LIMITATIONS,
        input
      ),
    export: () => HttpClient.get(API_ENDPOINTS.GET_SINGLE_BUSINESS_EXPORT),
  };
  dailyLimitsBusiness = {
    all: () =>
      HttpClient.get<DailyLimit[]>(
        API_ENDPOINTS.GET_DAILY_BUSINESS_LIMITATIONS
      ),
    update: (input: DailyLimitUpdate) =>
      HttpClient.put<DailyLimit>(
        API_ENDPOINTS.GET_DAILY_BUSINESS_LIMITATIONS,
        input
      ),
    export: () => HttpClient.get(API_ENDPOINTS.GET_DAILY_BUSINESS_EXPORT),
  };
  limitations = {
    all: () =>
      HttpClient.get<Limitation[]>(API_ENDPOINTS.GET_SINGLE_LIMITATIONS),
    update: (input: LimitationUpdate) =>
      HttpClient.put<Limitation>(API_ENDPOINTS.GET_SINGLE_LIMITATIONS, input),
    export: () => HttpClient.get(API_ENDPOINTS.GET_SINGLE_EXPORT),
  };
  dailyLimits = {
    all: () =>
      HttpClient.get<DailyLimit[]>(API_ENDPOINTS.GET_DAILY_LIMITATIONS),
    update: (input: DailyLimitUpdate) =>
      HttpClient.put<DailyLimit>(API_ENDPOINTS.GET_DAILY_LIMITATIONS, input),
    export: () => HttpClient.get(API_ENDPOINTS.GET_DAILY_EXPORT),
  };
  fee = {
    all: (input: ParamsSort) =>
      HttpClient.get<Fee[]>(API_ENDPOINTS.GET_FEES_INDIVIDUAL, input),
    update: (input: FeeUpdate) => {
      return HttpClient.put<Fee>(
        API_ENDPOINTS.GET_FEES_INDIVIDUAL_PERCENT,
        input
      );
    },
    updateRate: (input: FeeUpdate) => {
      return HttpClient.put<Fee>(API_ENDPOINTS.GET_FEES_INDIVIDUAL, input);
    },
    add: (input: ParamsSort) =>
      HttpClient.post<Fee>(API_ENDPOINTS.GET_FEES_INDIVIDUAL, input),
    delete: (id: number) =>
      HttpClient.delete<Fee>(`${API_ENDPOINTS.GET_FEES_INDIVIDUAL}/${id}`),
    export: () => HttpClient.get(API_ENDPOINTS.GET_FEES_INDIVIDUAL_EXPORT),
    listFee: () => HttpClient.get<string[]>(API_ENDPOINTS.GET_FIAT_LIST),
    listChanels: () => HttpClient.get<string[]>(API_ENDPOINTS.GET_CHANELS),
  };
  feeBusiness = {
    all: (input: ParamsSort) =>
      HttpClient.get<Fee[]>(API_ENDPOINTS.GET_FEES_BUSINESS, input),
    update: (input: FeeUpdate) =>
      HttpClient.put<Fee>(API_ENDPOINTS.GET_FEES_BUSINESS_PERCENT, input),
    updateRate: (input: FeeUpdate) =>
      HttpClient.put<Fee>(API_ENDPOINTS.GET_FEES_BUSINESS, input),
    add: (input: ParamsSort) =>
      HttpClient.post<Fee>(API_ENDPOINTS.GET_FEES_BUSINESS, input),
    delete: (id: number) =>
      HttpClient.delete<Fee>(`${API_ENDPOINTS.GET_FEES_BUSINESS}/${id}`),
    export: () => HttpClient.get(API_ENDPOINTS.GET_FEES_BUSINESS_EXPORT),
    listFee: () => HttpClient.get<string[]>(API_ENDPOINTS.GET_FIAT_LIST),
    listChanels: () => HttpClient.get<string[]>(API_ENDPOINTS.GET_CHANELS),
  };
  pendingDeposits = {
    individual: (input: PaginationInput) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_INDIVIDUAL_PENDING_DEPOSIT,
        input
      ),
    confirmIndividual: (input: { id: number; amount: number }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.CONFIRM_INDIVIDUAL_PENDING_DEPOSIT,
        input
      ),
    cancelIndividual: (input: { id: number }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.CANCEL_INDIVIDUAL_PENDING_DEPOSIT,
        input
      ),
    buisiness: (input: PaginationInput) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_BUSINESS_PENDING_DEPOSIT,
        input
      ),
    confirmBuisiness: (input: { id: number; amount: number }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.CONFIRM_BUSINESS_PENDING_DEPOSIT,
        input
      ),
    cancelBuisiness: (input: { id: number }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.CANCEL_BUSINESS_PENDING_DEPOSIT,
        input
      ),
  };
  unidentifiedDeposits = {
    all: (input: PaginationInput) =>
      HttpClient.get<UnidentifyDepositResponse>(
        API_ENDPOINTS.GET_UNIDENTIFIED_DEPOSITS,
        input
      ),
    confirm: (input: { id: number; user_id: number }) =>
      HttpClient.put<StatusResponse>(
        API_ENDPOINTS.CONFIRM_UNIDENTIFIED_DEPOSIT,
        input
      ),
    reject: (input: { id: number; comment: string }) =>
      HttpClient.put<StatusResponse>(
        API_ENDPOINTS.REJECT_UNIDENTIFIED_DEPOSIT,
        input
      ),
    findUser: (input: { search_field?: string; search_value?: string }) =>
      HttpClient.get<User[]>(API_ENDPOINTS.FIND_USER, input),
  };
  pendingWithdrawals = {
    individual: (input: PaginationInput) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_INDIVIDUAL_PENDING_WITHDRAWAL,
        input
      ),
    confirmIndividual: (input: { id: number }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.CONFIRM_INDIVIDUAL_PENDING_WITHDRAWAL,
        input
      ),
    cancelIndividual: (input: { id: number; comment: string }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.CANCEL_INDIVIDUAL_PENDING_WITHDRAWAL,
        input
      ),
    buisiness: (input: PaginationInput) =>
      HttpClient.get<TransactionsResponse>(
        API_ENDPOINTS.GET_BUSINESS_PENDING_WITHDRAWAL,
        input
      ),
    confirmBuisiness: (input: { id: number }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.CONFIRM_BUSINESS_PENDING_WITHDRAWAL,
        input
      ),
    cancelBuisiness: (input: { id: number; comment: string }) =>
      HttpClient.post<StatusResponse>(
        API_ENDPOINTS.CANCEL_BUSINESS_PENDING_WITHDRAWAL,
        input
      ),
  };
  ourPrices = {
    all: (input: IGetOurPricesInput) =>
      HttpClient.get<TOurPricesResponse>(API_ENDPOINTS.OUR_PRICES, input),
    update: (input: IUpdateOurPricesInput) =>
      HttpClient.put<IOurPricesItem>(API_ENDPOINTS.OUR_PRICES, input),
  };
  individualUserSettings = {
    all: (id: number) =>
      HttpClient.get<IuserSettingsResponse>(
        API_ENDPOINTS.GET_INDIVIDUAL_USET_SETTINGS(id)
      ),
  };
  busienessUserSettings = {
    all: (id: number) =>
      HttpClient.get<IuserSettingsResponse>(
        API_ENDPOINTS.GET_BUISINESS_USET_SETTINGS(id)
      ),
  };
}

export default new Client();
