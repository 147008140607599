import React, { FC, useState, useEffect, useContext } from "react";
import Input from "../../components/ui/input";
import Password from "src/components/ui/password";
import Button from "src/components/ui/button";
import { ResetIcon } from "../../components/icons/reset-icon";
import ReCAPTCHA from "react-google-recaptcha";
import routes from "src/config/routes";
import { useNavigate, Navigate } from "react-router-dom";
import { fadeInBottom } from "src/lib/framer-motion/fade-in-bottom";
import { motion } from "framer-motion";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import client from "../../api";
import useAuth from "../../hooks/use-auth";
import { getErrorMessage } from "../../lib/utils";
import { ConfigValue } from "src/config";
import GlobalContext from "src/context/global-context";
import { LogoIcon } from "src/components/icons/logo-icon";
import InputCode from "src/components/ui/input-code";
import { APISomethingWrongMsg, NetworkDisconnectMsg, permissions } from "src/lib/constants";

const schema = yup
  .object({
    email: yup
      .string()
      .required("This field is required.")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please write a valid email address."
      ),
    password: yup
      .string()
      .required("This field is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Pasword must be at least 8 characters long, include 1 uppercase letter, 1 special character and 1 number."
      ),
    totp: yup
      .string()
      .required("This field is required.")
      .matches(/^[0-9]{6}$/, "Invalid code. Please try again."),
    captcha: yup.string().required("This field is required.").min(3),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

const SignInPage: FC = () => {
  const navigate = useNavigate();

  const recaptchaRef = React.createRef<ReCAPTCHA>();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      totp: "000000",
    },
  });
  const [curPassword, curEmail] = watch(["password", "email"]);

  const { setAlertText, setIsAlertOpened } = useContext(GlobalContext);
  const [captchaValue, setCaptchaValue] = useState("");

  const { authorize, setCurrentUser, checkPermission } = useAuth();
  const [totpNeeded, setTotpNeeded] = useState(false);
  const [resetpCatpcha, setResetpCatpcha] = useState(false);

  const { mutate: login, isLoading } = useMutation(client.auth.login, {
    onSuccess: (data) => {
      console.log(data);
      authorize(data.token);
      setCurrentUser(data.user_data, curPassword);
      if (checkPermission([permissions.DASHBOARD])) {
        navigate(routes.dashboard);
      }
    },
    onError: (error: any) => {
      if (error.code === "ERR_NETWORK") {
        setAlertText(NetworkDisconnectMsg);
      } else {
        if (error.response) {
          if (
            error.response.data.errors.totp &&
            error.response.data.errors.totp[0] === "the_totp_field_is_required"
          ) {
            setTotpNeeded(true);
            setValue("totp", "");
          } else if (
            error.response.data.errors.captcha &&
            error.response.data.errors.captcha[0] === "validationrecaptcha"
          ) {
            setResetpCatpcha(true);
          }

          setAlertText(getErrorMessage(error.response.data));
        } else {
          setAlertText(APISomethingWrongMsg);
        }
      }
      setIsAlertOpened(true);
    },
  });

  const { isAuthorized } = useAuth();
  if (isAuthorized) return checkPermission([permissions.DASHBOARD]) ? <Navigate to={routes.dashboard} /> : null;

  const onSubmit = (data: FormData) => {
    login({
      email: data.email,
      password: data.password,
      ...(totpNeeded && { totp: data.totp }),
      captcha: captchaValue,
    });
  };

  const handleChange = (value: string | null) => {
    console.log("Captcha value:", value);
    setCaptchaValue(value ? value : "");
    setValue("captcha", value ? value : "", { shouldValidate: true });

    //this.setState({ value });
    // if value is null recaptcha expired
    //if (value === null) this.setState({ expired: "true" });
  };

  const asyncScriptOnLoad = () => {
    console.log("scriptLoad - reCaptcha Ref-", recaptchaRef);
  };

  const resetCaptcha = () => {
    recaptchaRef.current?.reset();
  };

  return (
    <motion.div
      variants={fadeInBottom()}
      className="justify-center items-center w-full m-auto"
    >
      <div className="flex items-center w-full px-4 justify-center py-[10px]">
        <form
          className="flex h-full w-full sm:w-[481px] py-8 flex-col px-4 sm:px-8 bg-white rounded-[12px]"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="flex flex-col gap-2 items-center justify-center">
            <LogoIcon className="w-[50px] h-[50px]" />
            <p className="text-[34px] font-medium font-manrope text-primary text-center sm:text-left">
              Login Backoffice
            </p>
          </div>
          <div className="flex flex-col mt-10 gap-4">
            <Input
              label="Email"
              id="new-email"
              autoComplete="new-email"
              className="w-full"
              inputClassName="h-[50px] sm:!h-[60px]"
              {...register("email")}
              error={errors.email && (errors.email.message as string)}
            />
            <Password
              label="Password"
              id="new-password"
              autoComplete="new-password"
              className="w-full"
              inputClassName="h-[50px] sm:!h-[60px]"
              {...register("password")}
              error={errors.password && (errors.password.message as string)}
            />
            <Controller
              name="totp"
              control={control}
              render={({ field }) => (
                <InputCode
                  maxLength={6}
                  label="Two-factor authentication"
                  placeholder="Enter 2FA code"
                  className={`${!totpNeeded && "hidden"} w-full`}
                  inputClassName="h-[50px] sm:!h-[60px]"
                  error={errors.totp && (errors.totp.message as string)}
                  {...field}
                />
              )}
            />
            <div className="flex flex-col mt-4">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={ConfigValue.CAPTCHA!}
                className="inline-block"
                onChange={handleChange}
                asyncScriptOnLoad={asyncScriptOnLoad}
              />
              {resetpCatpcha && (
                <div
                  className="flex items-center cursor-pointer"
                  onClick={() => resetCaptcha()}
                >
                  <ResetIcon className="w-[20px] h-[20px] text-secondary" />
                  <Button>reset CAPTCHA</Button>
                </div>
              )}
              {errors.captcha && (
                <span
                  role="alert"
                  className="block pt-2 text-[16px] text-[#F44336] font-manrope"
                >
                  {errors.captcha.message as string}
                </span>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-8 mt-6">
            <Button
              variant="validate"
              type="submit"
              className="rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[64px]"
              disabled={!isValid || isLoading}
              isLoading={isLoading}
            >
              Login
            </Button>
          </div>
        </form>
      </div>
    </motion.div>
  );
};

export default SignInPage;
