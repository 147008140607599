export const ResetIcon: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="256"
      height="256"
      viewBox="0 0 256 256"
      {...props}
    >
      <g fill="#000" strokeMiterlimit="10" strokeWidth="0">
        <path
          d="M78.761 56.581a3 3 0 00-3.955 1.535A32.586 32.586 0 0145 77.555c-15.098 0-27.825-10.331-31.491-24.295l1.872 1.858a3.001 3.001 0 004.227-4.26l-8.05-7.988c-.015-.015-.034-.024-.049-.039a2.949 2.949 0 00-.434-.345c-.056-.036-.115-.062-.173-.094a3.134 3.134 0 00-.382-.186c-.071-.027-.144-.045-.217-.067a2.964 2.964 0 00-.38-.091 2.868 2.868 0 00-.262-.026c-.073-.006-.142-.022-.216-.022-.051 0-.099.013-.149.015-.043.002-.085-.006-.128-.002-.05.005-.096.023-.146.03a3.053 3.053 0 00-.603.149c-.12.044-.234.097-.346.155-.072.037-.143.072-.212.115-.112.07-.215.151-.316.234-.058.048-.118.091-.173.143a3.064 3.064 0 00-.321.37c-.023.031-.053.055-.076.087l-.019.027L.53 52.627a3 3 0 004.937 3.41l1.887-2.732c3.81 17.279 19.237 30.25 37.644 30.25a38.587 38.587 0 0035.296-23.019 2.998 2.998 0 00-1.533-3.955zM88.705 33.2a3.002 3.002 0 00-4.174.763l-1.887 2.732C78.835 19.416 63.408 6.445 45 6.445A38.59 38.59 0 009.704 29.464a3 3 0 005.49 2.42A32.588 32.588 0 0145 12.445c15.098 0 27.825 10.332 31.49 24.296l-1.872-1.858a3 3 0 10-4.226 4.259l8.05 7.988c.043.043.095.074.141.115.099.087.197.176.308.25.06.04.127.067.19.103.107.061.211.125.325.172.075.031.155.047.232.072.108.035.214.076.327.099a2.985 2.985 0 00.867.047c.05-.005.096-.023.145-.03a2.914 2.914 0 00.952-.305c.071-.037.141-.071.209-.113.113-.071.217-.152.319-.236.057-.047.117-.089.171-.141.118-.113.221-.238.32-.368.024-.032.054-.056.077-.089l6.445-9.332a3.003 3.003 0 00-.765-4.174z"
          transform="matrix(2.81 0 0 2.81 1.407 1.407)"
        ></path>
      </g>
    </svg>
  );
};
