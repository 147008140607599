import { FC, useContext, useEffect, useState } from "react";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { useMutation } from "react-query";
import client from "../../api";
import moment from "moment";
import { Tab } from "@headlessui/react";
import classNames from "classnames";
import { User } from "src/api/types";
import TransactionsDepositPage from "./transactions-deposit";
import TransactionsWithdrawalPage from "./transactions-withdrawal";
import TransactionsKwickpayPage from './transactions-kwickpay';
import TransactionsTransferPage from "./transactions-transfer";

type UserTransactionsPageProps = {
  user: User;
  onSelectedInvoiceData: any;
};

const UserTransactionsPage: FC<UserTransactionsPageProps> = ({
  user,
  onSelectedInvoiceData,
}) => {
  const tabLists = ["Deposit", "Withdraw", "Transfer"];

  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const makeTabList = () => {
    const tabListsUI = tabLists.map((item, index) => (
      <Tab
        key={`tab_list_${index}`}
        className={({ selected }) =>
          classNames(
            "relative pb-1.5 before:absolute before:left-0 before:bottom-0 before:h-0.5 before:bg-primary before:transition-all before:duration-300 before:ease-in-out hover:text-primary",
            "ring-transparent ring-opacity-60 ring-offset-0 ring-offset-transparent focus:outline-none focus:ring-0",
            {
              "font-semibold text-primary before:w-full": selected,
              "text-secondary before:w-0 dark:text-light-800": !selected,
            }
          )
        }
      >
        <p className="text-[14px] sm:text-[16px]">{item}</p>
      </Tab>
    ));
    return (
      <Tab.List className="px-2 sm:px-8 mt-2 sm:mt-4 font-manrope font-medium relative space-x-4 sm:space-x-8 z-10 flex items-center">
        {tabListsUI}
      </Tab.List>
    );
  };

  return (
    <div className="bg-white rounded-[12px] w-full">
      <div className="p-2 relative">
        <Tab.Group>
          {makeTabList()}
          <Tab.Panels>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <TransactionsDepositPage
                user={user}
                onSelectedInvoiceData={onSelectedInvoiceData}
              />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <TransactionsWithdrawalPage
                user={user}
                onSelectedInvoiceData={onSelectedInvoiceData}
              />
            </Tab.Panel>
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <TransactionsKwickpayPage
                user={user}
                onSelectedInvoiceData={onSelectedInvoiceData}
              />
            </Tab.Panel>
            {/*
            <Tab.Panel className="border-0 focus:border-0 focus:ring-0 ring-0">
              <TransactionsTransferPage user={user} />
            </Tab.Panel>

              */}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};

export default UserTransactionsPage;
