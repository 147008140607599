import routes from "../../config/routes";
import { DashboardIcon } from "src/components/icons/dashboard-icon";
import { UserIcon } from "src/components/icons/user-icon";
import { PendingWithdrawalIcon } from "src/components/icons/pending-withdrawal-icon";
import { PendingDepositIcon } from "src/components/icons/pending-deposit-icon";
import { UnidentifiedDepositsIcon } from "src/components/icons/unidentified-deposits-icon";
import { FeeIcon } from "src/components/icons/fee-icon";
import { BankIcon } from "src/components/icons/bank-icon";
import { AdminsIcon } from "src/components/icons/admins-icon";
import { LimitationsIcon } from "src/components/icons/limitations-icon";
import { CurrencyIcon } from "src/components/icons/currency-icon";
import { TransactionHistoryIcon } from "src/components/icons/transaction-history-icon";
import { NotificationIcon } from "src/components/icons/notification-icon";
import { SettingsIcon } from "src/components/icons/settings-icon";
import { OurPricesIcon } from "src/components/icons/our-prices-icon";
import { ArrowUpIcon } from "../../components/icons/arrow-up";
import { ArrowDownIcon } from "../../components/icons/arrow-down";
import { FastTransferIcon } from "../../components/icons/fast-transfer";

export const CODE_EXPIRATION_TIME: number = 60;
export const LIST_PAGE_SIZE: number = 10;

export const BlockMsg =
  "Your account has been blocked, please contact the support for help";
export const NetworkDisconnectMsg =
  "Network is disconnected. Please check your internet connection!";
export const APISomethingWrongMsg =
  "Something went wrong. Please try again later!";

export const desktopOrLaptop = 768;
export const laptop = 1024;
export const macLaptop = 1440;

export const permissions = {
  DASHBOARD: "dashboard",
  USER_MANAGEMENT: "user management",
  PENDING_WITHDRAWALS: "pending withdrawals",
  PENDING_DEPOSITS: "pending deposits",
  TRANSACTIONS: "transactions",
  FEE_MANAGEMENT: "fee management",
  ADMIN_MANAGEMENT: "admin management",
  LIMITATIONS: "limitations",
  CURRENCY: "currency",
  NOTIFICATIONS: "notifications",
  SETTINGS: "settings",
  INDIVIDUAL_USERS: "individual users",
  BUSINESS_USERS: "business users",
  OUR_PRICES: "our prices",
  BANK_ACCOUNTS: "bank accounts",
  MANAGE_UNIDENTIFIED_DEPOSITS: "manage unidentified deposits",
};

export const FullSidebarMenus = [
  {
    orderId: 1,
    permission: permissions.DASHBOARD,
    title: "Dashboard",
    link: routes.dashboard,
    icon: <DashboardIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <DashboardIcon className="h-[20px] w-[20px] text-primary" />,
  },
  {
    orderId: 2,
    permission: permissions.USER_MANAGEMENT,
    title: "User Management",
    link: routes.users,
    icon: <UserIcon className="h-[20px] w-[20px] text-secondary" />,
    arrowUpIcon: <ArrowUpIcon className="h-[20px] w-[20px] text-secondary" />,
    arrowDownIcon: (
      <ArrowDownIcon className="h-[20px] w-[20px] text-secondary" />
    ),
    activeIcon: <UserIcon className="h-[20px] w-[20px] text-primary" />,
    subMenu: [
      {
        permission: permissions.INDIVIDUAL_USERS,
        title: "Individual",
        link: routes.users,
      },
      {
        permission: permissions.BUSINESS_USERS,
        title: "Business",
        link: routes.usersBusiness,
      },
    ],
  },
  {
    orderId: 3,
    permission: permissions.TRANSACTIONS,
    title: "Transactions History",
    link: routes.transactions,
    icon: (
      <TransactionHistoryIcon className="h-[20px] w-[20px] text-secondary" />
    ),
    arrowUpIcon: <ArrowUpIcon className="h-[20px] w-[20px] text-secondary" />,
    arrowDownIcon: (
      <ArrowDownIcon className="h-[20px] w-[20px] text-secondary" />
    ),
    activeIcon: (
      <TransactionHistoryIcon className="h-[20px] w-[20px] text-primary" />
    ),
    subMenu: [
      {
        title: "Individual",
        permission: permissions.INDIVIDUAL_USERS,
        link: routes.transactions,
      },
      {
        title: "Business",
        permission: permissions.BUSINESS_USERS,
        link: routes.transactionsBusiness,
      },
    ],
  },
  {
    orderId: 4,
    permission: permissions.PENDING_WITHDRAWALS,
    title: "Pending Withdrawals",
    link: routes.pendingWithdrawals,
    arrowUpIcon: <ArrowUpIcon className="h-[20px] w-[20px] text-secondary" />,
    arrowDownIcon: (
      <ArrowDownIcon className="h-[20px] w-[20px] text-secondary" />
    ),
    icon: (
      <PendingWithdrawalIcon className="h-[20px] w-[20px] text-secondary" />
    ),
    activeIcon: (
      <PendingWithdrawalIcon className="h-[20px] w-[20px] text-primary" />
    ),
    subMenu: [
      {
        title: "Individual",
        permission: permissions.INDIVIDUAL_USERS,
        link: routes.pendingWithdrawals,
      },
      {
        title: "Business",
        permission: permissions.BUSINESS_USERS,
        link: routes.pendingWithdrawalsBuisiness,
      },
    ],
  },
  {
    orderId: 5,
    permission: permissions.MANAGE_UNIDENTIFIED_DEPOSITS,
    title: "Unidentified deposit",
    link: routes.unidentifiedDeposits,
    icon: (
      <UnidentifiedDepositsIcon className="h-[20px] w-[20px] text-secondary" />
    ),
    activeIcon: (
      <UnidentifiedDepositsIcon className="h-[20px] w-[20px] text-primary" />
    ),
  },
  {
    orderId: 6,
    permission: permissions.PENDING_DEPOSITS,
    title: "Pending Deposit",
    link: routes.pendingDeposit,
    arrowUpIcon: <ArrowUpIcon className="h-[20px] w-[20px] text-secondary" />,
    arrowDownIcon: (
      <ArrowDownIcon className="h-[20px] w-[20px] text-secondary" />
    ),
    icon: <PendingDepositIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: (
      <PendingDepositIcon className="h-[20px] w-[20px] text-primary" />
    ),
    subMenu: [
      {
        title: "Individual",
        permission: permissions.INDIVIDUAL_USERS,
        link: routes.pendingDeposit,
      },
      {
        title: "Business",
        permission: permissions.BUSINESS_USERS,
        link: routes.pendingDepositBuisiness,
      },
    ],
  },
  {
    orderId: 7,
    permission: permissions.OUR_PRICES,
    title: "Our prices",
    link: routes.ourPrices,
    icon: <OurPricesIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <OurPricesIcon className="h-[20px] w-[20px] text-primary" />,
  },
  {
    orderId: 8,
    title: "Fee Management",
    permission: permissions.FEE_MANAGEMENT,
    link: routes.feeManagement,
    arrowUpIcon: <ArrowUpIcon className="h-[20px] w-[20px] text-secondary" />,
    arrowDownIcon: (
      <ArrowDownIcon className="h-[20px] w-[20px] text-secondary" />
    ),
    icon: <FeeIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <FeeIcon className="h-[20px] w-[20px] text-primary" />,
    subMenu: [
      {
        title: "Individual",
        permission: permissions.INDIVIDUAL_USERS,
        link: routes.feeManagement,
      },
      {
        title: "Business",
        permission: permissions.BUSINESS_USERS,
        link: routes.feeManagementBusiness,
      },
    ],
  },
  {
    orderId: 9,
    permission: permissions.ADMIN_MANAGEMENT,
    title: "Admin Management",
    link: routes.admins,
    icon: <AdminsIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <AdminsIcon className="h-[20px] w-[20px] text-primary" />,
  },
  {
    orderId: 10,
    permission: permissions.LIMITATIONS,
    title: "Limitations",
    link: routes.limitations,
    icon: <LimitationsIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <LimitationsIcon className="h-[20px] w-[20px] text-primary" />,
    arrowUpIcon: <ArrowUpIcon className="h-[20px] w-[20px] text-secondary" />,
    arrowDownIcon: (
      <ArrowDownIcon className="h-[20px] w-[20px] text-secondary" />
    ),
    subMenu: [
      {
        title: "Individual",
        permission: permissions.INDIVIDUAL_USERS,
        link: routes.limitations,
        activeIcon: (
          <LimitationsIcon className="h-[20px] w-[20px] text-primary" />
        ),
      },
      {
        title: "Business",
        permission: permissions.BUSINESS_USERS,
        link: routes.limitationsBusiness,
      },
    ],
  },
  {
    orderId: 11,
    permission: permissions.NOTIFICATIONS,
    title: "Notification",
    link: routes.notification,
    icon: <NotificationIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <NotificationIcon className="h-[20px] w-[20px] text-primary" />,
  },
  {
    orderId: 12,
    title: "Fast international money transfers",
    link: routes.fastTransfer,
    icon: <FastTransferIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <FastTransferIcon className="h-[20px] w-[20px] text-primary" />,
  },
  {
    orderId: 13,
    permission: permissions.BANK_ACCOUNTS,
    title: "Bank Account",
    link: routes.bankAccounts,
    icon: <BankIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <BankIcon className="h-[20px] w-[20px] text-primary" />,
  },
  {
    orderId: 14,
    title: "Currency",
    link: routes.currency,
    icon: <CurrencyIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <CurrencyIcon className="h-[20px] w-[20px] text-primary" />,
  },
  {
    orderId: 15,
    title: "Settings",
    link: routes.settings,
    icon: <SettingsIcon className="h-[20px] w-[20px] text-secondary" />,
    activeIcon: <SettingsIcon className="h-[20px] w-[20px] text-primary" />,
  },
];

export const TransactionType = [
  {
    id: 1,
    name: "All",
    code: "all",
    active: 1,
  },
  {
    id: 2,
    name: "Deposit",
    code: "deposit",
    active: 1,
  },
  {
    id: 3,
    name: "Withdrawal",
    code: "withdraw",
    active: 1,
  },
];

export enum SUCCESS_MODAL_TYPE {
  ChangePassword = "change_password",
  KYC = "kyc",
  ForgotPassword = "forgot_password",
  EmailVerification = "email_verification",
  ExternaTransfer = "external_transfer",
}

export enum PAYMENT_TYPE {
  Deposit = "deposit",
  Withdrawal = "withdrawal",
}

export enum PAYMENT_OPTIONS {
  Paypal = "paypal",
  Bank = "bank_transfer",
  Card = "cartubank",
  Fts = "fts",
}

export const userManagementFilterOptions = [
  {
    code: "user_id",
    name: "User ID",
  },
  {
    code: "full_name",
    name: "Full name",
  },
  {
    code: "phone",
    name: "Phone",
  },
  {
    code: "email",
    name: "Email",
  },
  {
    code: "country",
    name: "Country",
  },
  {
    code: "level",
    name: "Level",
  },
  {
    code: "status",
    name: "Status",
  },
];

export const transactionFilterOptions = [
  {
    code: "currency",
    name: "Currency",
  },
  {
    code: "fee",
    name: "Fee",
  },
  {
    code: "amount",
    name: "Amount",
  },
  {
    code: "payment_system",
    name: "Payment method",
  },
  {
    code: "status",
    name: "Status",
  },
  {
    code: "full_name",
    name: "Name",
  },
  {
    code: "user_id",
    name: "User ID",
  },
];

export const pendingWithdrawalFilterOptions = [
  {
    code: "user_id",
    name: "User ID",
  },
  {
    code: "currency",
    name: "Currency",
  },
  {
    code: "amount",
    name: "Amount",
  },
  {
    code: "payment_system",
    name: "Payment method",
  },
];

export const kwickpayTransactionsFilterOptions = [
  {
    code: "status",
    name: "Status",
  },
  {
    code: "user_id",
    name: "User ID",
  },
  {
    code: "currency",
    name: "Currency",
  },
  {
    code: "deposit_method",
    name: "Deposit method",
  },
  {
    code: "amount",
    name: "Amount",
  },
  {
    code: "sender_name",
    name: "Sender name",
  },
  {
    code: "sender_country",
    name: "Sender country",
  },
];

export const bankTypeOptions = [
  {
    value: 1,
    label: "Partner Bank",
  },
  {
    value: 0,
    label: "Other bank",
  },
];

export const unidentDepositFilterOptions = [
  {
    code: "external_payment_id",
    name: "External payment ID",
  },
  {
    code: "user_id",
    name: "User ID",
  },
  {
    code: "payment_id",
    name: "Payment ID",
  },
  {
    code: "admin_id",
    name: "Admin ID",
  },
  {
    code: "sender_name",
    name: "Sender name",
  },
  {
    code: "status",
    name: "Status",
  },
  {
    code: "asset_id",
    name: "Currency",
  },
  {
    code: "external_processing_system",
    name: "Payment method",
  },
  {
    code: "sender_bank_name",
    name: "Bank name",
  },
  {
    code: "sender_iban",
    name: "IBAN",
  },
];
