import { FC, useContext, useEffect, useState } from "react";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { User } from "src/api/types";
import { capitalizeFirstLetter } from "../../lib/utils";
import { useModalAction } from "../../components/modal-views/context";
import { Item } from "./user-item";
import Button from "../ui/button";

type UserAccountInfoPageProps = {
  user: User;
};

const UserAccountInfoPage: FC<UserAccountInfoPageProps> = ({ user }) => {
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const { openModal } = useModalAction();

  const onVerify = () => {
    openModal("VERIFY_USER", { user });
  };

  const onReject = () => {
    openModal("REJECT_USER", { user });
  };

  const employmentStatus = (name: string) => {
    switch (name) {
      case "unemployed":
        return "Temporarily unemployed";
      case "citizen":
        return "Senior citizen";

      default:
        return capitalizeFirstLetter(name);
    }
  };

  const sourceAmount = (name: string) => {
    switch (name) {
      case "tiny":
        return "0-1000";
      case "small":
        return "1001-5000";
      case "medium":
        return "5001-10000";
      case "large":
        return ">10000";
      default:
        return capitalizeFirstLetter(name);
    }
  };
  const anualAmount = (name: string) => {
    switch (name) {
      case "tiny":
        return "<10000";
      case "small":
        return "10 000 - 100 000";
      case "medium":
        return "100 000 - 500 000";
      case "large":
        return "> 500 000";
      default:
        return capitalizeFirstLetter(name);
    }
  };

  const reasonUsing = (name: string) => {
    switch (name) {
      case "utility_bills":
        return "Utility bills";
      case "deposits":
        return "For deposi";
      default:
        return capitalizeFirstLetter(name);
    }
  };

  const frequently = (name: string) => {
    switch (name) {
      case "one":
        return "One-time use";
      case "month":
        return "Several times a month";
      case "year":
        return "Several times a year";
      default:
        return capitalizeFirstLetter(name);
    }
  };

  const individualFields = (
    <>
      <div className="grid grid-cols-3 mt-6">
        <div>
          <Item title="TIN" value={user.aml_user_data?.tin} />
          <Item
            title="Employment status"
            value={employmentStatus(
              String(user.aml_user_data?.employment_status)
            )}
          />
          <Item title="Name of work" value={user.aml_user_data?.work_name} />
        </div>
        <div>
          <Item title="Job title" value={user.aml_user_data?.job_title} />
          <Item
            title="Source of income"
            value={capitalizeFirstLetter(
              String(user.aml_user_data?.income_source)
            )}
          />
          <Item
            title="From-to"
            value={sourceAmount(String(user.aml_user_data?.income_amount))}
          />
        </div>
        <div>
          <div className="grid mt-6">
            <div className="flex flex-col gap-6">
              <div>
                <p className="text-secondary font-manrope text-[14px] font-medium flex-shrink-0">
                  What will be the turnover per year on e-wallet:
                </p>
                <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                  {anualAmount(String(user.aml_user_data?.annual_turnover))}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row items-center justify-between w-full border-b-2 border-gray border-opacity-50 gap-2 pb-4 mt-8">
        <p className="text-primary font-manrope text-[18px] font-medium leading-[28px]">
          Other
        </p>
      </div>
      <div className="grid grid-cols-3">
        <div>
          <div className="grid mt-6">
            <div className="flex flex-col gap-6">
              <div>
                <p className="text-secondary font-manrope text-[14px] font-medium flex-shrink-0">
                  What will be the turnover per year on e-wallet:
                </p>
                <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                  {anualAmount(String(user.aml_user_data?.annual_turnover))}
                </p>
              </div>
            </div>
          </div>
          <Item
            title="Your purposes"
            value={
              user.aml_business_data?.reason_for_using === "other"
                ? user.aml_business_data?.reason_for_using_details
                : capitalizeFirstLetter(
                    user.aml_user_data?.reason_for_using?.replaceAll(
                      "_",
                      " "
                    ) || ""
                  )
            }
          />
        </div>
        <div>
          <div className="grid mt-6">
            <div className="flex flex-col gap-6">
              <div>
                <p className="text-secondary font-manrope text-[14px] font-medium flex-shrink-0">
                  Do you have dual citizenship?
                </p>
                <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                  {user.aml_user_data?.dual_citizenship != null &&
                    (!!user.aml_user_data?.dual_citizenship ? "Yes" : "No")}
                </p>
              </div>
            </div>
          </div>
          gdfg
          {user.aml_user_data?.dual_citizenship && (
            <Item
              title="Country"
              value={user.aml_user_data?.dual_citizenship_country}
            />
          )}
        </div>
        <div>
          <div className="grid mt-6">
            <div className="flex flex-col gap-6">
              <div>
                <p className="text-secondary font-manrope text-[14px] font-medium flex-shrink-0">
                  How ofter should you use Fastoo per year?
                </p>
                <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                  {frequently(String(user.aml_user_data?.utilize_frequency))}
                </p>
              </div>
            </div>
          </div>
          <div className="grid mt-6">
            <div className="flex flex-col gap-6">
              <div>
                <p className="text-secondary font-manrope text-[14px] font-medium flex-shrink-0">
                  Do you have a green card?
                </p>
                <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                  {user.aml_user_data?.has_green_card != null &&
                    (!!user.aml_user_data?.has_green_card ? "Yes" : "No")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const buisinessFields = (
    <>
      <div className="grid grid-cols-3 mt-6">
        <div>
          <Item
            title="Employment status"
            value={employmentStatus(
              String(user.aml_business_data?.employment_status)
            )}
            noMt
          />
          <Item
            title="Name of work"
            value={user.aml_business_data?.work_name}
          />
          <Item title="Job title" value={user.aml_business_data?.job_title} />
        </div>
        <div>
          <div className="flex flex-col gap-6">
            <div>
              <p className="text-secondary font-manrope text-[14px] font-medium flex-shrink-0">
                Do you have dual citizenship?
              </p>
              <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                {user.aml_business_data?.dual_citizenship != null &&
                  (!!user.aml_business_data?.dual_citizenship ? "Yes" : "No")}
              </p>
            </div>
          </div>
          <Item
            title="Country"
            value={user.aml_business_data?.dual_citizenship_country}
          />
        </div>
        <div>
          <div className="flex flex-col">
            <div>
              <p className="text-secondary font-manrope text-[14px] font-medium flex-shrink-0">
                Do you have a green card?
              </p>
              <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                {user.aml_business_data?.has_green_card != null &&
                  (!!user.aml_business_data?.has_green_card ? "Yes" : "No")}
              </p>
            </div>
          </div>
          <Item
            title="Source of income"
            value={capitalizeFirstLetter(
              user.aml_business_data?.income_source || ""
            )}
          />
          <Item
            title="From-to"
            value={sourceAmount(String(user.aml_business_data?.income_amount))}
          />
        </div>
      </div>
      <div className="flex flex-row items-center justify-between w-full border-b-2 border-gray border-opacity-50 gap-2 pb-4 mt-8">
        <p className="text-primary font-manrope text-[18px] font-medium leading-[28px]">
          Other
        </p>
      </div>
      <div className="grid grid-cols-3">
        <div>
          <div className="grid mt-6">
            <div className="flex flex-col gap-6">
              <div>
                <p className="text-secondary font-manrope text-[14px] font-medium flex-shrink-0">
                  What are the reasons for using Fastoo?
                </p>
                <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                  {reasonUsing(
                    String(user.aml_business_data?.reason_for_using)
                  )}
                </p>
              </div>
            </div>
          </div>
          <Item
            title="Your purposes"
            value={
              user.aml_business_data?.reason_for_using === "other"
                ? user.aml_business_data?.reason_for_using_details
                : ""
            }
          />
        </div>
        <div>
          <div className="grid mt-6">
            <div className="flex flex-col gap-6">
              <p className="text-secondary font-manrope text-[14px] font-medium flex-shrink-0">
                How ofter should you intend to utilize Fastoo?
              </p>
              <p className="text-primary font-manrope text-[14px] font-medium flex-shrink">
                {frequently(String(user.aml_business_data?.utilize_frequency))}
              </p>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </>
  );

  return (
    <div className="bg-white rounded-r-[12px] rounded-bl-[12px] w-full relative">
      <div className="p-4 relative">
        <div className="flex flex-row items-center justify-between w-full border-b-2 border-gray border-opacity-50 gap-2 pb-4">
          <p className="text-primary font-manrope text-[18px] font-medium leading-[28px]">
            Job information
          </p>
        </div>
        {user.is_business ? buisinessFields : individualFields}
        {["unverified", "pending"].includes(user.status?.name) && (
          <div className="w-full flex flex-row items-center justify-end gap-2 mt-2 sm:mt-4">
            <Button
              variant="validate"
              type="submit"
              className="w-full sm:w-[230px] rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[56px]"
              onClick={onVerify}
            >
              Verify
            </Button>
            <Button
              variant="solid"
              className="w-full sm:w-[230px] rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[56px] bg-[#BCBEAD] hover:bg-light-600"
              onClick={onReject}
            >
              Reject
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserAccountInfoPage;
