import {
  FC,
  useContext,
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
  useRef,
} from "react";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusStyle,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Dropdown, MenuProps, Space, Spin, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
} from "src/lib/constants";
import { PaginationInput, User } from "src/api/types";
import SearchUserIDBox from "src/components/shared/search-box";
import {
  TransactionDataType,
  UserDataType,
} from "src/lib/constants/table-type";
import { ColumnsType } from "antd/es/table";
import { LockIcon } from "src/components/icons/lock-icon";
import { UnlockIcon } from "src/components/icons/unlock-icon";
import Button from "src/components/ui/button";
import ToggleSwitch from "src/components/ui/switch";
import { BackCircleIcon } from "src/components/icons/back-circle-icon";
import UsersContainerPage from "src/components/users";
import InvoicePage from "src/components/invoice";
import SearchFilter from "src/components/search-filters/user-management-filter";
import { userManagementFilterOptions } from "src/lib/constants";

interface IUserManagementPage {
  isBuisiness?: boolean;
}

const UserManagementPage: FC<IUserManagementPage> = ({ isBuisiness }) => {
  const [searchParams, setSearchParams] = useState<PaginationInput>({
    per_page: LIST_PAGE_SIZE,
    current_page: 1,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ id: string }>();
  const { id } = params;
  const curentId: number = id ? Number(id) : -1;

  const [searchText, setSearchText] = useState("");
  const [filter, setFilter] = useState("user_id");
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [selectedUserID, setSelectedUserID] = useState(Number(curentId));
  const [userData, setUserData] = useState<User>();
  const [blockUserID, setBlockUserID] = useState(-1);

  const pathCurrent = location.pathname.split("/")[1];
  const [dataSource, setDataSource] = useState<UserDataType[]>([]);
  const [total, setTotal] = useState(0);
  const [totalUsers, setTotalUsers] = useState<User[]>([]);
  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const [invoiceData, setInvoiceData] = useState<TransactionDataType | null>(
    null
  );
  console.log(selectedUserID);

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    containerRef.current && setContainerWidth(containerRef.current.offsetWidth);
    setIsMobile(window.innerWidth < 768 ? true : false);
  }

  const onSelectedInvoiceData = (data: TransactionDataType) => {
    setInvoiceData(data);
  };

  const onBackFromInvoice = () => {
    setInvoiceData(null);
  };

  const onPageChange = (page: number, pageSize: number) => {
    console.log(page, pageSize);
    setSearchParams({
      ...searchParams,
      current_page: page,
    });
  };

  const { mutate: getUsers, isLoading } = useMutation(
    isBuisiness
      ? client.users.getBuisinessUsers
      : client.users.getIndividualUsers,
    {
      onSuccess: (data) => {
        console.log(data);
        setTotal(data.total);
        setTotalUsers(data.users);
        setDataSource(
          data.users.map((item) => ({
            key: item.id,
            id: item.id,
            full_name:
              !item.user_data.first_name && !item.user_data.last_name
                ? "-"
                : (item.user_data.first_name ?? " ") +
                  " " +
                  (item.user_data.last_name ?? " "),
            level: item.is_vip === 1 ? "VIP" : "Regular",
            email: item.email,
            phone: item.user_data.phone ?? " ",
            country: item.user_data.country ?? " ",
            blocked: item.blocked,
            status: item.status.name,
            is_vip: item.is_vip,
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: exportUsers } = useMutation(
    isBuisiness
      ? client.users.getBuisinessUsers
      : client.users.getIndividualUsers,
    {
      onSuccess: (data) => {
        console.log(data);
        setAlertText("Export success");
        setIsAlertOpened(true);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isMounted) {
        getUsers({
          ...searchParams,
          search_value: searchText || undefined,
          search_field: searchText ? filter : undefined,
        });
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [isMounted, searchParams.current_page, searchText, isBuisiness]);

  const exportBtn = async () => {
    try {
      setTimeout(() => {
        exportUsers({
          ...searchParams,
          search_value: searchText || undefined,
          search_field: searchText ? filter : undefined,
          export: 1,
        });
      }, 300);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  useEffect(() => {
    if (!id) {
      setSelectedUserID(-1);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      getUserData(Number(selectedUserID));
    }
  }, [id]);

  useEffect(() => {
    setSearchParams({
      ...searchParams,
      current_page: 1,
    });
  }, [searchText]);

  const { mutate: blockUser, isLoading: isLoadingBlock } = useMutation(
    client.users.blockUser,
    {
      onSuccess: (data) => {
        console.log(data);

        setAlertText(
          data.blocked === 1
            ? "Account has been blocked"
            : "Account has been unblocked"
        );
        setIsAlertOpened(true);

        const newData = [...dataSource];
        const index = newData.findIndex((item) => blockUserID == item.key);
        if (index > -1) {
          const item = newData[index];
          const editingData = { ...item, blocked: data.blocked };
          newData.splice(index, 1, {
            ...item,
            ...editingData,
          });
          setDataSource(newData);
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: updateVip, isLoading: isLoadingVip } = useMutation(
    isBuisiness
      ? client.users.updateBuisinessVip
      : client.users.updateIndividualVip,
    {
      onSuccess: (data) => {
        console.log(data);

        const newData = [...dataSource];
        const index = newData.findIndex((item) => data.id == item.key);
        if (index > -1) {
          const item = newData[index];
          const editingData = { ...item, is_vip: data.is_vip };
          newData.splice(index, 1, {
            ...item,
            ...editingData,
          });
          setDataSource(newData);
        }
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: getUserData } = useMutation(
    isBuisiness
      ? client.busienessUserSettings.all
      : client.individualUserSettings.all,
    {
      onSuccess: (data: any) => {
        console.log(data);
        setUserData(data);
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
      },
    }
  );

  const onBlockUser = (
    id: number,
    currentStatus: number,
    newStatus: number
  ) => {
    if (currentStatus === newStatus) {
      return;
    }

    setBlockUserID(id);
    setTimeout(() => blockUser({ id, blocked: newStatus }), 100);
  };

  const onSelectUser = (userID: number) => {
    setSelectedUserID(userID);
    navigate(`/${pathCurrent}/${String(userID)}`, { replace: true });
  };

  const onUpdateVip = (id: number, value: boolean) => {
    updateVip({
      userId: id,
      input: {
        is_vip: value ? 1 : 0,
      },
    });
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a className="text-primary text-[13px] font-manrope font-light">Lock</a>
      ),
    },
    {
      key: "2",
      label: (
        <a className="text-primary text-[13px] font-manrope font-light">
          Unlock
        </a>
      ),
    },
  ];

  const columns: ColumnsType<UserDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          ID
        </p>
      ),
      dataIndex: "id",
      className: "w-auto",
      key: "id",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <a
            className="font-manrope text-secondary text-[14px] font-medium underline hover:text-primary hover:underline"
            onClick={() => onSelectUser(value)}
          >
            {value}
          </a>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Full name
        </p>
      ),
      dataIndex: "full_name",
      className: "w-auto",
      key: "full_name",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {capitalizeEachFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          E-mail
        </p>
      ),
      dataIndex: "email",
      className: "w-auto",
      key: "email",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-secondary text-[14px] font-medium">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Phone number
        </p>
      ),
      dataIndex: "phone",
      key: "phone",
      className: "w-auto",
      render: (value: number) => (
        <p className="font-manrope text-primary text-[14px] font-normal">
          {value}
        </p>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Country
        </p>
      ),
      dataIndex: "country",
      className: "w-auto",
      key: "country",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-normal">
            {value}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Status
        </p>
      ),
      dataIndex: "status",
      key: "status",
      className: "w-auto",
      render: (value) => (
        <div className="flex flex-row gap-2 items-center rounded-full">
          <p
            className={`font-manrope text-[12px] font-medium px-2 rounded-full ${getStatusStyle(
              value
            )}`}
          >
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Level
        </p>
      ),
      className: "w-auto",
      render: (value) => {
        return (
          <div
            className="flex flex-row gap-2 items-center rounded-full justify-between"
            style={{ width: "90%" }}
          >
            <p className="font-manrope text-[14px] font-medium px-2 rounded-full">
              {value.is_vip ? "VIP" : "Regular"}
            </p>
            <ToggleSwitch
              type="isVip"
              value={value.is_vip === 1}
              onValueChanged={() => onUpdateVip(value.id, !value.is_vip)}
              disabled={isLoadingVip}
            />
          </div>
        );
      },
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key; blocked: number }) => (
        <div className="flex flex-row gap-2">
          <button
            className="w-[30px] h-[30px] flex items-center justify-center"
            onClick={() => onBlockUser(record.key as number, record.blocked, 1)}
          >
            <LockIcon
              className={`${
                record.blocked == 1 ? "text-primary" : "text-secondary"
              } w-[20px] h-[20px]`}
            />
          </button>
          <button
            className="w-[30px] h-[30px] flex items-center justify-center"
            onClick={() => onBlockUser(record.key as number, record.blocked, 0)}
          >
            <UnlockIcon
              className={`${
                record.blocked == 0 ? "text-primary" : "text-secondary"
              } w-[20px] h-[20px]`}
            />
          </button>
        </div>
      ),
    },
  ];

  const mobileColumns: ColumnsType<UserDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Info
        </p>
      ),
      dataIndex: "action",
      className: "w-auto",
      render: (_, record: { key: React.Key }) => {
        const selData =
          dataSource.filter((item) => item.key === record.key)[0] || {};

        const onClick: MenuProps["onClick"] = ({ key }) => {
          if (key === "1") {
            onBlockUser(record.key as number, selData.blocked, 1);
          } else {
            onBlockUser(record.key as number, selData.blocked, 0);
          }
        };

        return (
          <div className="flex flex-row items-start justify-between gap-2">
            <div
              className="flex flex-col items-start gap-2"
              style={{ width: (containerWidth - 20) / 2 }}
            >
              <div className="w-full flex flex-row items-center justify-between gap-2 leading-[21px] min-h-[21px]">
                <p className="text-secondary text-[14px] font-manrope font-medium flex-shrink">
                  ID
                </p>
                <a
                  className="font-manrope text-secondary text-[14px] font-medium underline hover:text-primary hover:underline  text-right"
                  onClick={() => onSelectUser(selData.id)}
                >
                  {selData.id}
                </a>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2 leading-[21px] min-h-[21px]">
                <p className="text-secondary text-[14px] font-manrope font-medium flex-shrink">
                  Full Name
                </p>
                <p className="font-manrope text-primary text-[14px] font-medium  text-right">
                  {capitalizeEachFirstLetter(selData.full_name)}
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2 leading-[21px] min-h-[21px]">
                <p className="text-secondary text-[14px] font-manrope font-medium flex-shrink">
                  Level
                </p>
                <p className="font-manrope text-primary text-[14px] font-medium text-right">
                  <div
                    className="flex flex-row gap-2 items-center rounded-full justify-between"
                    style={{ width: "90%" }}
                  >
                    <p className="font-manrope text-[14px] font-medium px-2 rounded-full">
                      {selData.is_vip ? "VIP" : "Regular"}
                    </p>
                    <ToggleSwitch
                      type="isVip"
                      value={selData.is_vip === 1}
                      onValueChanged={() =>
                        onUpdateVip(selData.id, !selData.is_vip)
                      }
                    />
                  </div>
                </p>
              </div>
              <div className="w-full flex flex-row items-center justify-between gap-2 leading-[21px] min-h-[21px]">
                <p className="text-secondary text-[14px] font-manrope font-medium flex-shrink">
                  Status
                </p>
                <p
                  className={`font-manrope text-[12px] font-medium px-2 rounded-full ${getStatusStyle(
                    selData.status
                  )}`}
                >
                  {capitalizeFirstLetter(selData.status)}
                </p>
              </div>
            </div>
            <div
              className="flex flex-col items-start gap-2"
              style={{ width: (containerWidth - 20) / 2.4 }}
            >
              <p className="break-words inline-block w-full font-manrope text-secondary text-[14px] font-medium leading-[21px] min-h-[21px] text-right">
                {selData.email}
              </p>
              <p className="w-full truncate font-manrope text-primary text-[14px] font-normal leading-[21px] min-h-[21px] text-right">
                {selData.phone}
              </p>
              <p className="w-full truncate font-manrope text-primary text-[14px] font-normal leading-[21px] min-h-[21px] text-right">
                {selData.country}
              </p>
              <Space direction="vertical" className="w-full text-right">
                <Dropdown menu={{ items, onClick }}>
                  <a className="w-full truncate font-manrope text-secondary text-[14px] font-medium underline hover:text-primary hover:underline leading-[21px] min-h-[21px] text-right">
                    Actions
                  </a>
                </Dropdown>
              </Space>
            </div>
          </div>
        );
      },
    },
  ];

  /*
  const makeSubPage = () => {
    return invoiceData ? (
      <InvoicePage data={invoiceData!} callback={onBackFromInvoice} />
    ) : (
      <div className="flex flex-col w-full p-2 sm:p-4">
        <div className="flex flex-row items-center justify-between sm:justify-start gap-4">
          <div className="flex flex-row items-center gap-2">
            <Button
              variant="icon"
              className="w-[24px] bg-transparent h-[24px]"
              onClick={() => setSelectedUserID(-1)}
            >
              <BackCircleIcon className="w-[24px] h-[24px] text-primary" />
            </Button>
            <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[38px] sm:leading-[48px]">
              {
                dataSource.filter((item) => item.key === selectedUserID)[0]
                  .full_name
              }
            </p>
          </div>
          <p
            className={`font-manrope text-[12px] font-medium px-2 rounded-full ${getStatusStyle(
              dataSource.filter((item) => item.key === selectedUserID)[0].status
            )}`}
          >
            {capitalizeFirstLetter(
              dataSource.filter((item) => item.key === selectedUserID)[0].status
            )}
          </p>
        </div>
        <UsersContainerPage
          user={totalUsers.filter((item) => item.id === selectedUserID)[0]}
          onSelectedInvoiceData={onSelectedInvoiceData}
        />
      </div>
    );
  };
  */

  const selectedUser = totalUsers.find(
    (item) => item.id === Number(selectedUserID)
  );

  return (
    <div className="w-full h-full bg-gray">
      <div className="w-full h-full flex-col">
        {selectedUserID < 0 ? (
          <div className="flex flex-col w-full p-2 sm:p-4">
            <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
              User Management
            </p>
            <div className="mt-4 flex flex-col sm:flex-row items-start sm:items-center justify-between gap-2">
              <p className="text-[16px] font-manrope font-medium leading-[24px] text-primary">
                {`All Users (${total})`}
              </p>
              <div className="flex align-center gap-2">
                <SearchFilter
                  options={userManagementFilterOptions}
                  value={filter}
                  onChange={setFilter}
                  searchText={searchText}
                  setSearchText={setSearchText}
                />
                <button
                  type="button"
                  onClick={() => exportBtn()}
                  className="button button--type3 button--gap30"
                >
                  Export Excel
                  <span className="btn-icon btn-icon--fill">
                    <svg
                      width="17"
                      height="17"
                      viewBox="0 0 17 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.79961 1.8002L12.6996 5.4002M8.79961 1.8002L5.19961 5.4002M8.79961 1.8002L8.79961 13.2002M15.9996 8.4002V16.2002L1.59961 16.2002L1.59961 8.4002"
                        stroke="#232800"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
            <div
              className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4"
              ref={containerRef}
            >
              <Table
                loading={isLoading || isLoadingBlock}
                showSorterTooltip={false}
                columns={isMobile ? mobileColumns : columns}
                dataSource={dataSource}
                pagination={{
                  total,
                  current: searchParams.current_page,
                  onChange: onPageChange,
                  position: ["bottomCenter"],
                  defaultPageSize: LIST_PAGE_SIZE,
                  showSizeChanger: false,
                }}
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col w-full">
            <div
              className={`${
                invoiceData && "hidden"
              } flex flex-col w-full p-2 sm:p-4`}
            >
              <div className="flex flex-row items-center justify-between sm:justify-start gap-4">
                <div className="flex flex-row items-center gap-2">
                  <Button
                    variant="icon"
                    className="w-[24px] bg-transparent h-[24px]"
                    onClick={() => {
                      setSelectedUserID(-1);
                      navigate(`/${pathCurrent}`, { replace: true });
                    }}
                  >
                    <BackCircleIcon className="w-[24px] h-[24px] text-primary" />
                  </Button>
                  <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[38px] sm:leading-[48px]">
                    {
                      dataSource.filter(
                        (item) => item.key === selectedUserID
                      )?.[0]?.full_name
                    }
                  </p>
                </div>
                <p
                  className={`font-manrope text-[12px] font-medium px-2 rounded-full ${getStatusStyle(
                    dataSource.filter(
                      (item) => item.key === selectedUserID
                    )?.[0]?.status
                  )}`}
                >
                  {capitalizeFirstLetter(
                    dataSource.filter(
                      (item) => item.key === selectedUserID
                    )?.[0]?.status || ""
                  )}
                </p>
              </div>
              {userData && (
                <UsersContainerPage
                  user={userData}
                  onSelectedInvoiceData={onSelectedInvoiceData}
                />
              )}
            </div>
            {invoiceData && (
              <InvoicePage data={invoiceData!} callback={onBackFromInvoice} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserManagementPage;
