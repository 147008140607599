import { Permission } from "src/api/types";
import { CurrencyEURIcon } from "../components/icons/currency-eur-icon";
import { CurrencyGBPIcon } from "../components/icons/currency-gbp-icon";
import { CurrencyGELIcon } from "../components/icons/currency-gel-icon";
import { CurrencyUAHIcon } from "../components/icons/currency-uah-icon";
import { CurrencyUSDIcon } from "../components/icons/currency-usd-icon";
import { CurrencyCNYIcon } from "../components/icons/currency-cny-icon";
import { APISomethingWrongMsg, FullSidebarMenus } from "./constants";
import moment from "moment";
import Cookies from "js-cookie";

export const makeSidebarMenu = (permissions: Permission[]) => {
  const menus = permissions
    .map((permission) => {
      const permissionNames = permissions.map((item) => item.name);
      const menuItem = FullSidebarMenus.find(
        (menu) => menu.permission === permission.name
      );
      if (menuItem?.subMenu) {
        const filteredSub = menuItem?.subMenu.filter((menu) =>
          permissionNames.includes(menu.permission)
        );
        if (filteredSub.length === 0) {
          return null;
        }
        menuItem.subMenu = filteredSub;
      }
      return menuItem;
    })
    .filter((item) => item);

  menus.sort(function (a, b) {
    return a && b ? a.orderId - b.orderId : 0;
  });

  return menus.filter((item) => item != null);
};

export const getPaymentOptionName = (item: string) => {
  switch (item) {
    case "paypal":
      return "Paypal";
    case "bank_transfer":
      return "Bank";
    case "cartubank":
      return "Card";
    default:
      return "Paypal";
  }
};

export const getTransactionStatusStyle = (value: string) => {
  switch (value) {
    case "waiting":
      return "bg-secondary text-white";
    case "completed":
      return "bg-active text-primary";
    case "canceled":
      return "bg-error text-white";
    default:
      return "bg-primary text-white";
  }
};

export const getStatusStyle = (value: string) => {
  switch (value) {
    case "pending":
      return "bg-secondary text-white";
    case "approved":
      return "bg-active text-primary";
    case "rejected":
      return "bg-error text-white";
    case 'identified':
      return "bg-active text-primary";
    case 'unidentified':
      return "bg-secondary text-white";
    default:
      return "bg-primary text-white";
  }
};

export const getKYCStatusName = (value: string) => {
  switch (value) {
    case "pending":
      return "PENDING";
    case "approved":
      return "VERIFIED";
    case "rejected":
      return "REJECTED";
    default:
      return "NOT VERIED";
  }
};

export const getKYCStatusStyle = (value: string) => {
  switch (value) {
    case "pending":
      return "text-error font-manrope text-[14px] font-medium flex-shrink";
    case "approved":
      return "text-success font-manrope text-[14px] font-medium flex-shrink";
    case "rejected":
      return "text-error font-manrope text-[14px] font-medium flex-shrink";
    default:
      return "text-error font-manrope text-[14px] font-medium flex-shrink";
  }
};

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeEachFirstLetter = (str: string): string => {
  const updatedStrs = str
    .split(" ")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1));
  return updatedStrs.join(" ");
};

export const convertPaymentSystem = (str: string): string => {
  const updatedStrs = str
    .split("_")
    .map((item) => item.charAt(0).toUpperCase() + item.slice(1));
  return updatedStrs.join(" ");
};

function removeFalsy(obj: any) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => Boolean(v)));
}

export const getErrorMessage = (data: any) => {
  if (data.errors) {
    const errors = data.errors;
    if (Array.isArray(errors)) {
      const messages = errors.map((error: string) => error.replace(/_/g, " "));
      return messages.length > 0
        ? `error_${capitalizeFirstLetter(messages[0])}`
        : APISomethingWrongMsg;
      //return messages.join("\n");
    } else {
      const values = Object.values(errors) as any[];
      const messages = values.map((eachValue: string[]) =>
        eachValue[0].replace(/_/g, " ")
      );
      return messages.length > 0
        ? `error_${capitalizeFirstLetter(messages[0])}`
        : APISomethingWrongMsg;
    }
  } else {
    return `error_${data.message}`;
  }
};

export function formatAddress(address: any) {
  if (!address) return;
  const temp = ["street", "city", "post_code", "country"].reduce(
    (acc, k) => ({ ...acc, [k]: (address as any)[k] }),
    {}
  );
  const formattedAddress = removeFalsy(temp);
  return Object.values(formattedAddress).length === 0
    ? "-"
    : Object.values(formattedAddress).join(", ");
}

export function formatAddressWithoutCountry(address: any) {
  if (!address) return;
  const temp = ["street", "city", "post_code"].reduce(
    (acc, k) => ({ ...acc, [k]: (address as any)[k] }),
    {}
  );
  const formattedAddress = removeFalsy(temp);
  return Object.values(formattedAddress).length === 0
    ? "-"
    : Object.values(formattedAddress).join(" ");
}

export function getCurrencyInfo(name: string, size: number = 24) {
  switch (name.toUpperCase()) {
    case "USD":
      return {
        icon: (
          <CurrencyUSDIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "United States Dollar",
      };
    case "EUR":
      return {
        icon: (
          <CurrencyEURIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "Euro",
      };
    case "GEL":
      return {
        icon: (
          <CurrencyGELIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "Georgian Lari",
      };
    case "GBP":
      return {
        icon: (
          <CurrencyGBPIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "British Pound",
      };
    case "UAH":
      return {
        icon: (
          <CurrencyUAHIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "Ukraine Hryvnia",
      };
    case "CNY":
      return {
        icon: (
          <CurrencyCNYIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "Chinese Yuan",
      };
    default:
      return {
        icon: (
          <CurrencyUSDIcon
            className={`w-[${size}px] h-[${size}px] flex-shrink-0`}
          />
        ),
        descr: "United States Dollar",
      };
  }
}

export const makeSenderSecret = (value: string) => {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (expression.test(value)) {
    //email
    return value.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2");
  } else {
    //phone number
    return value.slice(0, 3) + value.slice(3).replace(/.(?=....)/g, "*");
  }
};

export const makePlaceholder = (value: string) => {
  const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  if (expression.test(value)) {
    //email
    return "Email verification code";
  } else {
    //phone number
    return "Phone verification code";
  }
};

export const makeSecretKey = (length: number) => {
  let result = "";
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const makeCardSecretCardNumber = (cardNumber: string) => {
  return cardNumber
    .replace(/.(?=.{4})/g, "x")
    .match(/.{1,4}/g)!
    .join(" ");
};

export const makeNumberWithFloatingDigits = (
  value: string | number,
  digits: number = 8
) => {
  return Number(value).toLocaleString(undefined, {
    minimumFractionDigits: digits,
  });
};

export const AuthenticatorAppURL = {
  ios: "https://apps.apple.com/ru/app/google-authenticator/id388497605",
  android:
    "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2",
};

export const verifyCardNumber = (cardNumber: string) => {
  let sum = 0;
  let temp = 0;
  let cardNumberCopy = cardNumber;
  let checkDigit = parseInt(cardNumber.slice(-1));
  let parity = cardNumberCopy.length % 2;

  for (let i = 0; i <= cardNumberCopy.length - 2; i++) {
    if (i % 2 === parity) {
      temp = +cardNumberCopy[i] * 2;
    } else {
      temp = +cardNumberCopy[i];
    }

    if (temp > 9) {
      temp -= 9;
    }

    sum += temp;
  }

  return (sum + checkDigit) % 10 === 0;
};

export const numberFormat = (value: number, format: string) => {
  const transformNumber = new Intl.NumberFormat(format, {
    maximumFractionDigits: 2,
  }).format(value);
  return transformNumber;
};

export function setTabIndex(name: string, nIdx: number) {
  Cookies.set(name, `${nIdx}`, { expires: 1 });
}

export function removeTab(name: string) {
  Cookies.remove(name);
}

export function getTabIndex(name: string) {
  const nIdx = Cookies.get(name);
  return nIdx;
}
