import {
  FC,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  useRef,
} from "react";
import { useModalAction } from "../../components/modal-views/context";
import { useMutation } from "react-query";
import client from "../../api";
import {
  capitalizeEachFirstLetter,
  capitalizeFirstLetter,
  getCurrencyInfo,
  getErrorMessage,
  getStatusStyle,
  makeNumberWithFloatingDigits,
} from "../../lib/utils";
import GlobalContext from "src/context/global-context";
import { useIsMounted } from "src/lib/hooks/use-is-mounted";
import { Form, InputNumber, Table } from "antd";
import {
  NetworkDisconnectMsg,
  APISomethingWrongMsg,
  LIST_PAGE_SIZE,
  SUCCESS_MODAL_TYPE,
} from "src/lib/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import useAuth from "src/hooks/use-auth";
import Password from "../ui/password";
import Button from "../ui/button";
import { AdminUser } from "src/api/types";
import Input from "../ui/input";
import { PermissionDataType } from "src/lib/constants/table-type";
import ToggleSwitch from "../ui/switch";
import { ColumnsType } from "antd/es/table";
import { callback } from "chart.js/dist/helpers/helpers.core";

const schema = yup
  .object({
    full_name: yup
      .string()
      .trim()
      .matches(
        /^[a-zA-Z]+( [a-zA-Z]+)+$/,
        "Full name must be at least two words, do not contain numbers and special symbol!"
      )
      .max(60, "Full name max length must be not more 60 characters!")
      .required("Please, enter your full name!"),
    email: yup
      .string()
      .required("This field is required.")
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Please write a valid email address."
      ),
    password: yup
      .string()
      .required("This field is required.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Pasword must be at least 8 characters long, include 1 uppercase letter, 1 special character and 1 number."
      ),
    password_confirmation: yup
      .string()
      .required("This field is required.")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  })
  .required();
type FormData = yup.InferType<typeof schema>;

type AdminFormPageProps = {
  isNew: boolean;
  editingUser?: AdminUser;
  onCallback: any;
};

const AdminFormPage: FC<AdminFormPageProps> = ({
  isNew,
  editingUser,
  onCallback,
}) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const { openModal, closeModal } = useModalAction();

  const [dataSource, setDataSource] = useState<PermissionDataType[]>([]);

  dataSource.sort((a, b) => {
    if (a.id === 12 || a.id === 13) {
      return -1;
    }
    if (a.id === 13 || b.id === 12) {
      return 1;
    }
    return 0;
  });

  const { setIsAlertOpened, setAlertText } = useContext(GlobalContext);
  const isMounted = useIsMounted();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setError,
    reset,
    formState: { isValid, errors },
  } = useForm<FormData>({
    mode: "all",
    resolver: yupResolver(schema),
    defaultValues: {
      full_name: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
  });

  const fullName = watch("full_name");

  useEffect(() => {
    updateWindowSize();
    window.addEventListener("resize", updateWindowSize);

    return () => window.removeEventListener("resize", updateWindowSize);
  }, []);

  function updateWindowSize() {
    setIsMobile(window.innerWidth < 640 ? true : false);
  }

  useEffect(() => {
    if (editingUser) {
      setValue(
        "full_name",
        `${editingUser!.first_name ?? ""} ${editingUser!.last_name ?? ""}`,
        { shouldValidate: true }
      );
      setValue("email", editingUser!.email, {
        shouldValidate: true,
      });

      setValue("password", "Leftright_123!@#", {
        shouldValidate: true,
      });

      setValue("password_confirmation", "Leftright_123!@#", {
        shouldValidate: true,
      });
    } else {
      setValue("full_name", "");
      setValue("email", "");
      setValue("password", "");
      setValue("password_confirmation", "");
    }

    getList();
  }, [isNew, editingUser]);

  const checkPermissionStatus = (permission_id: number) => {
    if (!editingUser) return false;

    const index = editingUser!.permissions.findIndex(
      (item) => item.id === permission_id
    );

    return index > -1 ? true : false;
  };

  const { mutate: getList, isLoading } = useMutation(
    client.admins.permissions,
    {
      onSuccess: (data) => {
        setDataSource(
          data.map((item) => ({
            key: item.id,
            id: item.id,
            name: item.name,
            value: checkPermissionStatus(item.id),
          }))
        );
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: createNew, isLoading: isLoadingCreate } = useMutation(
    client.admins.add,
    {
      onSuccess: (data) => {
        console.log(data);
        openModal("ADMIN_SUCCESS_VIEW", {
          full_name: fullName,
          isNew,
          callback: onCallback,
        });
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const { mutate: updateOne, isLoading: isLoadingUpdate } = useMutation(
    client.admins.update,
    {
      onSuccess: (data) => {
        console.log(data);
        openModal("ADMIN_SUCCESS_VIEW", {
          full_name: fullName,
          isNew,
          callback: onCallback,
        });
      },
      onError: (error: any) => {
        if (error.code === "ERR_NETWORK") {
          setAlertText(NetworkDisconnectMsg);
        } else {
          if (error.response) {
            setAlertText(getErrorMessage(error.response.data));
          } else {
            setAlertText(APISomethingWrongMsg);
          }
        }
        setIsAlertOpened(true);
      },
    }
  );

  const onValueChanged = (type: string, value: boolean) => {
    console.log(type, value);
    const newData = [...dataSource];
    const index = newData.findIndex((item) => type == item.name);
    if (index > -1) {
      const item = newData[index];
      const editingData = { ...item, value };
      newData.splice(index, 1, {
        ...item,
        ...editingData,
      });
      setDataSource(newData);
    }
  };

  const onCancel = () => {
    onCallback(false);
  };

  const onSubmit = (data: FormData) => {
    const names = data.full_name.trim().split(" ");
    // if (names.length !== 2) {
    //   setError("full_name", {
    //     type: "custom",
    //     message: "Please write a valid full name(first & last name)",
    //   });

    //   return;
    // }

    const availablePermissions = dataSource.filter((item) => item.value);
    const permissions = availablePermissions.map((item) => ({ id: item.id }));

    if (isNew) {
      createNew({
        first_name: names[0],
        last_name: names[1],
        email: data.email,
        password: data.password,
        permissions,
      });
    } else {
      updateOne({
        id: editingUser!.id,
        params: { first_name: names[0], last_name: names[1], permissions },
      });
    }
  };

  const columns: ColumnsType<PermissionDataType> = [
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          Action name
        </p>
      ),
      dataIndex: "name",
      key: "name",
      className: "w-auto",
      render: (value: string) => (
        <div className="flex flex-row gap-2 items-center">
          <p className="font-manrope text-primary text-[14px] font-medium">
            {capitalizeFirstLetter(value)}
          </p>
        </div>
      ),
    },
    {
      title: () => (
        <p className="font-manrope text-secondary text-[14px] font-medium">
          On/Off
        </p>
      ),
      dataIndex: "value",
      key: "value",
      className: "w-[80px]",
      render: (_, record: { key: React.Key; name: string; value: boolean }) => (
        <div className="flex flex-row gap-2 items-center">
          <ToggleSwitch
            type={record.name}
            value={record.value}
            onValueChanged={onValueChanged}
          />
        </div>
      ),
    },
  ];

  return (
    <form
      className="w-full h-full flex-col p-2 sm:p-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="w-full flex flex-row items-center justify-between">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          {isNew ? "Create New Admin" : "Edit Admin"}
        </p>
      </div>

      <div className="flex flex-col md:flex-row mt-2 md:mt-4 gap-2 md:gap-4">
        <div className="bg-white rounded-[12px] p-4 w-full md:w-[380px]">
          <p className="text-[16px] font-medium font-manrope text-primary">
            Name
          </p>
          <Input
            label="Full name"
            placeholder="Enter full name"
            className="w-full mt-4"
            {...register("full_name")}
            error={errors.full_name && (errors.full_name.message as string)}
          />
          <Input
            label="E-mail"
            placeholder="Enter e-mail"
            id="new-email"
            autoComplete="new-email"
            className="w-full mt-4"
            disabled={editingUser ? true : false}
            {...register("email")}
            error={errors.email && (errors.email.message as string)}
          />
        </div>
        <div
          className={`bg-white rounded-[12px] p-4 w-full md:w-[380px] ${
            isNew ? "block" : "hidden"
          }`}
        >
          <p className="text-[16px] font-medium font-manrope text-primary">
            Password
          </p>
          <Password
            label="Password"
            id="new-password"
            autoComplete="new-password"
            placeholder="Enter your password"
            className="w-full mt-4"
            {...register("password")}
            error={errors.password && (errors.password.message as string)}
          />
          <Password
            label="Repeat Password"
            placeholder="Confirm your password"
            className="w-full mt-4"
            {...register("password_confirmation")}
            error={
              errors.password_confirmation &&
              (errors.password_confirmation.message as string)
            }
          />
        </div>{" "}
      </div>

      <div className="w-full flex flex-row items-center justify-between mt-2 md:mt-4">
        <p className="text-[25px] sm:text-[32px] font-manrope text-primary font-medium leading-[37.5px] sm:leading-[48px]">
          Permissions
        </p>
      </div>
      <div className="flex flex-col w-full bg-white rounded-[12px] p-4 mt-2 sm:mt-4">
        <Table
          loading={isLoading}
          showSorterTooltip={false}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </div>
      <div className="w-full flex flex-row items-center justify-end gap-2 mt-2 sm:mt-4">
        <Button
          variant="solid"
          className="w-full sm:w-[230px] rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[56px] bg-[#BCBEAD] hover:bg-light-600"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          variant="validate"
          type="submit"
          className="w-full sm:w-[230px] rounded-[10px] font-manrope text-[16px] font-bold leading-[24px] h-[56px]"
          disabled={!isValid || isLoadingCreate || isLoadingUpdate}
          isLoading={isLoadingCreate || isLoadingUpdate}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

export default AdminFormPage;
